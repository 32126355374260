import { Button, Dialog, DialogBody } from '@material-tailwind/react';
import { useEffect, useState } from 'react';
import { useAccount } from 'wagmi';
import { useUserAuth } from '../../../../Contexts/authContext';
import { useHelpers } from '../../../../Hooks/helpers';
import { useReadContract } from '../../../../Hooks/readContract';

export const usePoolHealthDialog = () => {
    const [isOpen, setIsOpen] = useState(false);
    const show = () => {
        setIsOpen(true);
    }
    return {
        isOpen: isOpen,  //state
        open: show, //open
        close: () => setIsOpen(false) //close
    };
}

export const PoolHealthDialog = ({ isOpen, dismiss, rewardsVal, proceedCallback }) => {

    const helper = useHelpers();
    const auth = useUserAuth();
    const readContract = useReadContract();
    const account = useAccount();

    const [claimConfig, setClaimConfig] = useState({});

    //Get user balance
    useEffect(() => {
        if(auth.appBootstrapped === true && account.isConnected === true) {
            getChainData();
        }
    }, [auth, account.isConnected]);

    const getChainData = async () => {
        const cbalance = await readContract.getContractBalance();
        setClaimConfig(helper.getPoolStability(cbalance));
    }
    
    return (
        <Dialog size='lg' open={isOpen} handler={dismiss} className=' bg-gradient-two rounded-[25px] max-h-[500px] overflow-auto sm:max-h-none'>
            <DialogBody className='text-center font-trunoRegular'>
                <div>
                    <h4 className='font-bold mb-3 text-white text-xl'>Current Commission Pool Payout</h4>
                    <div className={`inline-block rounded-xl p-3 px-5 text-white ${claimConfig.color}`}>
                        <div className='flex justify-center gap-5'>
                            <div className='font-bold text-sm'>
                                <h4 className='max-w-[170px] leading-4 font-normal mb-2'>Claim to your BNB wallet</h4>
                                <p className='text-4xl'>{claimConfig.claimPercent/100}%</p>
                            </div>
                            <div className='font-bold text-sm'>
                                <h4 className='max-w-[170px] leading-4 font-normal mb-2'>Claim to your LifeStyle Bucks balance</h4>
                                <p className='text-4xl'>{100-claimConfig.claimPercent/100}%</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='mt-6'>
                    <p className='text-white'>Rewards Claiming</p>
                    <p className='font-truenoBold text-4xl text-white text-shadow-one'><helper.AmountToCurrency amount={rewardsVal} decimals={4} /></p>
                </div>
                <div className='space-y-4 sm:space-y-0 sm:flex justify-center items-center mt-5 leading-4 text-sm bg-gradient-one rounded-2xl px-4 py-3 space-x-3 text-blue'>
                    <div className=''>
                        <p>Rewards you will receive to your wallet ({claimConfig.claimPercent/100}%)</p>
                        <p className='font-truenoBold text-2xl mt-1 text-white text-shadow-one'><helper.AmountToCurrency amount={rewardsVal*claimConfig.claimPercent/10000} decimals={4} /></p>
                    </div>
                    <div className=''>
                        <p>Rewards sent to your LifeStyle Bucks balance ({((10000-claimConfig.claimPercent)/100)}%)</p>
                        <p className='font-truenoBold text-2xl mt-1 text-white text-shadow-one'><helper.AmountToCurrency amount={rewardsVal*((10000-claimConfig.claimPercent)/10000)} decimals={4} /></p>
                    </div>
                    <div className=''>
                        <p>Rewards sent to your Level 1 sponsor (upto 15%)</p>
                        <p className='font-truenoBold text-2xl mt-1 text-white text-shadow-one'><helper.AmountToCurrency amount={rewardsVal*claimConfig.claimPercent/10000*0.15} decimals={4} /></p>
                    </div>
                    <div className=''>
                        <p>Rewards sent to your Level 2 sponsor (10%)</p>
                        <p className='font-truenoBold text-2xl mt-1 text-white text-shadow-one'><helper.AmountToCurrency amount={rewardsVal*claimConfig.claimPercent/10000*0.1} decimals={4} /></p>
                    </div>
                </div>
                <div className='mt-4'>
                    <Button color='amber' className='text-lg font-trunoSemibold text-blue' size='lg' onClick={proceedCallback}>Continue</Button>
                </div>
            </DialogBody>
        </Dialog>
    )
}