import { Button } from "@material-tailwind/react";
import React from "react";
import Countdown from "react-countdown";
import { useNavigate } from "react-router-dom";

const ComingSoon = () => {

  const navigate = useNavigate();

  return (
    <div className="shadow-lg sm:flex text-center items-center justify-between px-4 py-4 rounded-xl mb-5 bg-gradient-three">
      {/* <div className="sm:text-center"> */}
        {/* <div className="text-lg font-bold text-gray-800 ml-2 mt-2 leading-3">IAM New Version coming soon!,</div> */}
        <div className="text-center text-lg text-shadow-two font-bold font-trunoSemibold">IAM New Version coming soon!</div>
      {/* </div> */}
      {/* <div className="sm:text-right sm:pr-1 mt-5 sm:mt-0">
        <div className="font-bold text-md text-gray-900">Purchase Credits Now</div>
        <Countdown className="font-bold text-3xl font-trunoSemibold text-shadow-two" date={1716883200000} />
        <div className="mt-3"><Button onClick={() => navigate('/iam')} color="black">Get Ready</Button></div>
      </div> */}
    </div>
  );
};

export default ComingSoon;
