import { NumericStepper } from '@anatoliygatt/numeric-stepper';
import { Button } from "@material-tailwind/react";
import React, { useEffect, useState } from "react";
import Countdown from "react-countdown";
import { toast } from "react-hot-toast";
import Swal from "sweetalert2";
import { useAccount } from 'wagmi';
import packTrans from "../../../Assets/pack-trans.png";
import { useUserAuth } from "../../../Contexts/authContext";
import { useCallAPI } from "../../../Hooks/callAPI";
import { PendingModal } from "../../../Hooks/pendingModal";
import { usePollAPI } from "../../../Hooks/pollAPI";
import { useReadContract } from "../../../Hooks/readContract";
import { useWriteContract } from "../../../Hooks/writeContract";
import { PurchaseButton } from "../../../Utility/Buttons";

const PurchaseIam = () => {

  const auth = useUserAuth();
  const account = useAccount();
  const writeContract = useWriteContract();
  const readContract = useReadContract();
  const pollResults = usePollAPI();
  const callAPI = useCallAPI();

  const [userBalance, setUserBalance] = useState(false);
  const [blockDiv, setBlockDiv] = useState('');
  const [count, setCount] = useState(1);
  const [countLimit, setCountLimit] = useState(2);

  //Get user balance
  useEffect(() => {
    if(auth.appBootstrapped === true && account.isConnected === true) {
        getBalances();
    }
  }, [auth, account.isConnected]);

  const getBalances = async () => {
    setUserBalance(await readContract.getUserBalance());
    const limitRes = await readContract.readIam('MAX_COURSES_AT_ONCE');
    setCountLimit(limitRes.toString()*1);
  }

  const startPurchase = async () => {
      
      setBlockDiv('purchaseBtn');
      // DO prelim checks
      try {
          if(!account.isConnected) throw 'Connect Wallet';
          if(account.address.toLowerCase() != auth.userBootstrap.userData.address.toLowerCase()) { throw 'Switch to your registered wallet address'; }

          //If Presale active allow only 1 purchase per wallet
          const launched = await readContract.readIam('LAUNCHED_ON');
          if(launched.toString()*1 === 0) {
            //Check if user is whitelisted
            const isWhiteListed = await readContract.readIam('whitelistedAddress', [ account.address ]);
            if(!isWhiteListed)
              throw 'Please wait for Official Launch'; 
          }

          //Limit maximium packsCount to limit set in contract
          if(count*1 > countLimit) {
            throw 'Cannot buy more than ' + countLimit + ' credits at once.'; 
          }

          //Get required balance for pro purchase
          const res = await readContract.readIam('COURSE_COST');
          
          const cost = await readContract.getCost(res.toString()*count);
          if(userBalance.int < cost.int) { throw 'Insufficient Balance'; }

          purchaseNow(cost);

      } catch (error) {
        console.log(error);
          toast.error(error);
          setBlockDiv('');
      }

  }

  const purchaseNow = async (cost) => {
      setBlockDiv('purchaseBtn');
      await writeContract.call({ ca: 'iam', fn: "PurchaseCourse", params: [ count.toString() ], successCallback: purchaseCB, value: cost.bns});
      setBlockDiv('');
  }

  const purchaseCB = (hash) => {
      return new Promise(resolve => {
          (async () => {
              const apiToPoll = () => callAPI("checkChainTransaction/iam/" + hash);
              const pollContinueCondition = (res) => res.data.state === "pending";
              const pollRes = await pollResults(apiToPoll, pollContinueCondition, 5000);
              resolve();
              if(pollRes.data.state == "success") {
                  //Show success popup
                  Swal.fire({
                      title: "Purchase Complete!",
                      text: "Congratulations on purchasing " + count + " Course Credit(s)!",
                      icon: 'success'
                  });
                  await auth.getAppBootstrap();
              } else if(pollRes.data.state == "failed") {
                  toast.error("Failed to purchase.");
              }
          })();
      });
  }

  return (
    <>
      <div className='bg-gradient-seven relative rounded-[20px] box-shadow-[0px_10px_10px_0px_rgba(0,0,0,0.10)]'>
        <div className='p-7 text-center'>
          <h3 className='text-white text-shadow-one font-trunoSemibold tracking-[.9px] mb-4 text-[24px] md:text-3xl'>
            Purchase Course Credits
          </h3>
          <div className='mb-7'>
            <strong className='block text-white tracking-[1.95px] text-shadow-four leading-tight text-[50px] md:text-[60px] font-trunoSemibold'>
              $50
            </strong>
            <span className='tracking-[.54px] text-base text-white font-trunoRegular block'>
              Cost Per Credit
            </span>
          </div>
          <div className='mb-8 flex justify-center'>
            <NumericStepper
                  minimumValue={1}
                  stepValue={1}
                  maximumValue={countLimit}
                  initialValue={count}
                  size='sm'
                  inactiveTrackColor='#fff' // Blue equivalent of #DF7900
                  activeTrackColor='#162553' // No change needed, already blue-based
                  activeButtonColor='#DDEFFF' // Blue equivalent of #ffedd5
                  inactiveIconColor='#162553' // No change needed, already blue-based
                  hoverIconColor='#162553' // No change needed, already blue-based
                  activeIconColor='#001f3f' // Blue equivalent of #9a3412
                  disabledIconColor='#111' // No change needed
                  thumbColor='#194399' // No change needed
                  thumbLabelColor='#fff'// No change needed, already blue-based

                  thumbShadowAnimationOnTrackHoverEnabled={true}
                  focusRingColor="#fff"
                  onChange={setCount}
              />
          </div>
          {writeContract.modalShow ?
            <div className='mx-auto text-left max-w-xs'>
              <PendingModal show={writeContract.modalShow} data={writeContract.modalData} />
            </div>
            :
            <div className='flex justify-center'>
              <PurchaseButton onClick={startPurchase} loading={blockDiv === 'purchaseBtn' ? 1 : 0}>
                Purchase Now - 
                <span> ${count*50}</span>
              </PurchaseButton>
            </div>
          }
        </div>
      </div>
    </>
  );
};

export default PurchaseIam;