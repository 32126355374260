import { Button } from "@material-tailwind/react";
import React, { useEffect, useState } from "react";
import { useUserAuth } from '../../../Contexts/authContext';
import { useCallAPI } from "../../../Hooks/callAPI";
import { useHelpers } from "../../../Hooks/helpers";
import { EmptyRecords, LoadingData, TitlePrimary } from "../../../Utility/Utility";

const CoursesEnrolled = () => {

    const auth = useUserAuth();
    const helper = useHelpers();
    const callAPI = useCallAPI();

    const [data, setData] = useState([]);
    const [dataLoadFull, setDataLoadFull] = useState(false);
    const [loading, setLoading] = useState(false);

    //Get user balance
    useEffect(() => {
        if(auth.appBootstrapped === true) {
            getResults(0);
        }
    }, [auth]);

    const getResults = async (offset) => {
        setLoading(true);
        const res = await callAPI("getIamCoursesEnrolled" + "/" + offset);
        
        if(offset === 0 && res.data === false) {
            setData(false);
            return;
        }
        
        if(res.data !== false) {
            setData([...data, ...res.data]);
        } else {
            setDataLoadFull(true);
        }
        setLoading(false);
    }

    return (
        <>
            <div>
                <div className="mb-6">
                    <TitlePrimary>Courses Enrolled</TitlePrimary>
                </div>
                {data && data.length > 0 &&
                    <>
                        <div className="h-full w-full overflow-scroll">
                            <table className="w-full table-auto text-left overflow-hidden bg-gradient-six rounded-lg_next">
                                <thead>
                                    <tr className="uppercase font-trunoSemibold bg-gradient-two text-white text-xs">
                                        <th className="px-5 py-4">Courses enrolled</th>
                                        <th className="px-5 py-4"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                {data && data.length > 0 && data.map((e, i) => {
                                    return (
                                        <tr key={i} className="border-b border-[#ED1C24] text-[#123377] tracking-[.45px] font-trunoRegular text-[14px]">
                                            <td className="px-5 py-4">{e.course_title}<br/><p className="text-xs mt-2 opacity-80">{helper.ProcessDate(e.created)}</p></td>
                                            <td className="px-5 py-4">
                                                <a href={`https://iamvilocity.com/#/courses/${e.course_id}/about`} target="blank">
                                                    <Button size="sm" color="indigo" variant="gradient">Open</Button>
                                                </a>
                                            </td>
                                        </tr>
                                    )
                                })}
                                </tbody>
                            </table>
                            {!dataLoadFull && <Button color="cyan" disabled={loading} className="mt-4" onClick={() => getResults(data.length)}>Load More</Button>}
                        </div>
                    </>
                }
                {data && data.length === 0 &&
                    <LoadingData />
                }
                {data === false &&
                    <EmptyRecords title='No courses found' description='Your courses will be listed here.' />
                }
            </div>
        </>
    );
};

export default CoursesEnrolled;
