import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import Swal from "sweetalert2";
import { useAccount } from 'wagmi';
import { useUserAuth } from "../../../Contexts/authContext";
import { useCallAPI } from "../../../Hooks/callAPI";
import { useHelpers } from '../../../Hooks/helpers';
import { usePollAPI } from "../../../Hooks/pollAPI";
import { useReadContract } from "../../../Hooks/readContract";
import { useWriteContract } from "../../../Hooks/writeContract";
import { PendingModal } from "../../../Hooks/pendingModal";
import { PurchaseButton } from "../../../Utility/Buttons";

const RePurchaseIam = () => {

  const auth = useUserAuth();
  const helper = useHelpers();
  const writeContract = useWriteContract();
  const readContract = useReadContract();
  const pollResults = usePollAPI();
  const callAPI = useCallAPI();
  const account = useAccount();

  const [purchaseBalance, setPurchaseBalance] = useState(0);
  const [blockDiv, setBlockDiv] = useState('');

  //Get user balance
  useEffect(() => {
    if(auth.appBootstrapped === true && account.isConnected === true) {
        getBalances();
    }
  }, [auth, account.isConnected]);

  const getBalances = async () => {
    const purchaseBalRes = await readContract.readIam('users', [ account.address ]);
    setPurchaseBalance(purchaseBalRes[0].toString()/100);
  }

  const startPurchase = async () => {
      
      setBlockDiv('purchaseBtn');
      // DO prelim checks
      try {

          if(account.address.toLowerCase() != auth.userBootstrap.userData.address.toLowerCase()) { throw 'Switch to your registered wallet address'; }
          purchaseNow();

      } catch (error) {
        console.log(error);
          toast.error(error);
          setBlockDiv('');
      }

  }

  const purchaseNow = async () => {
      setBlockDiv('purchaseBtn');
      await writeContract.call({ ca: 'iam', fn: "purchasePackUsingPurchaseBalance", params: [], successCallback: purchaseCB});
      setBlockDiv('');
  }

  const purchaseCB = (hash) => {
    return new Promise(resolve => {
        (async () => {
            const apiToPoll = () => callAPI("checkChainTransaction/iam/" + hash);
            const pollContinueCondition = (res) => res.data.state === "pending";
            const pollRes = await pollResults(apiToPoll, pollContinueCondition, 5000);
            resolve();
            if(pollRes.data.state == "success") {
                //Show success popup
                Swal.fire({
                    title: "Purchase Complete!",
                    text: "Congratulations on purchasing course Credit(s)!",
                    icon: 'success'
                });
                await auth.getAppBootstrap();
            } else if(pollRes.data.state == "failed") {
                toast.error("Failed to purchase.");
            }
        })();
    });
  }

  return (
    <>
      <div className='bg-gradient-seven relative rounded-[20px] overflow-hidden px-8 py-6'>
        <div className="text-center">
          <p className='text-primary text-shadow-four font-trunoSemibold leading-7 text-2xl'>Purchase Course Credits using Purchase Balance</p>
        </div>
        <div className='text-center my-8'>
          <strong className='block text-primary text-shadow-four text-shadow-four leading-tight text-[35px] font-trunoSemibold'>
            <helper.AmountToCurrency amount={purchaseBalance} decimals={2} />
          </strong>
          <span className='text-sm text-shadow-one font-trunoRegular block'>
            Available Purchase balance
          </span>
        </div>
        <div>
          {writeContract.modalShow ?
            <div className='mx-auto text-left max-w-sm bg-white rounded-lg p-5 mt-4'>
              <PendingModal show={writeContract.modalShow} data={writeContract.modalData} />
            </div>
          :
            <div className='mt-8 text-center'>
              <div className='block text-primary text-shadow-four leading-tight text-[35px] mt-5 font-trunoSemibold'>{Math.floor(purchaseBalance / 50)}</div>
              <div className='text-sm text-shadow-one font-trunoRegular block'>Credits you can purchase</div>
              <div className="flex justify-center mt-8">
                <PurchaseButton onClick={startPurchase} loading={blockDiv === 'purchaseBtn' ? 1 : 0} disabled={Math.floor(purchaseBalance / 50) < 1}>
                  Purchase Now
                </PurchaseButton>
              </div>
            </div>
          }
        </div>
      </div>
    </>
  );
};

export default RePurchaseIam;
