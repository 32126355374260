import { CheckBadgeIcon } from '@heroicons/react/24/solid';
import { Button } from "@material-tailwind/react";
import React, { useEffect, useState } from "react";
import Countdown from "react-countdown";
import { toast } from "react-hot-toast";
import Swal from "sweetalert2";
import { useAccount } from 'wagmi';
import { useUserAuth } from "../../../Contexts/authContext";
import { useCallAPI } from "../../../Hooks/callAPI";
import { PendingModal } from "../../../Hooks/pendingModal";
import { usePollAPI } from "../../../Hooks/pollAPI";
import { useReadContract } from "../../../Hooks/readContract";
import { useWriteContract } from "../../../Hooks/writeContract";
import { LoadingData } from '../../../Utility/Utility';

const PurchasePack = () => {

  const auth = useUserAuth();
  const writeContract = useWriteContract();
  const readContract = useReadContract();
  const pollResults = usePollAPI();
  const callAPI = useCallAPI();
  const account = useAccount();

  const [selectedBundle, setSelectedBundle] = useState(false);
  const [userBalance, setUserBalance] = useState(false);
  const [blockDiv, setBlockDiv] = useState('');
  const [purchaseCooldown, setPurchaseCooldown] = useState(0);

  //Get user balance
  useEffect(() => {
    if(auth.appBootstrapped === true && account.isConnected === true) {
        getBalances();
    }
  }, [auth, account.isConnected]);

  const getBalances = async () => {
    setUserBalance(await readContract.getUserBalance());
    const purchaseRules = await readContract.read('getPurchaseRulesData', [ account.address ] );
    setPurchaseCooldown(purchaseRules[0].toString()*1);
  }

  const startPurchase = async (bundleId) => {
      
      setBlockDiv('purchaseBtn');
      // DO prelim checks
      try {

          setSelectedBundle(bundleId);

          if(account.address.toLowerCase() != auth.userBootstrap.userData.address.toLowerCase()) { throw 'Switch to your registered wallet address'; }

          //If Presale active allow only 1 purchase per wallet
          const launched = await readContract.read('LAUNCHED_ON');
          if(launched.toString()*1 === 0) {
            //Check if user is whitelisted
            const isWhiteListed = await readContract.read('whitelistedAddress', [ account.address ]);
            if(isWhiteListed) {
              //Check if 1 pack already bought. Show error
              if(auth?.userBootstrap.userTotalPacks*1 >= 1) {
                throw 'Only 1 pack allowed till Official launch'; 
              }
            } else {
              throw 'Please wait for Official Launch'; 
            }
          }

          const isBundlePurchased = await readContract.read('usersBundles', [ account.address, bundleId ]);
          if(isBundlePurchased) {
            throw 'Bundle already purchased';
          }

          //Get required balance for pro purchase
          const bundleCost = await readContract.read('bundlesCost', [ bundleId ]);
          
          const cost = await readContract.getCost(bundleCost.toString());
          console.log(cost);
          if(userBalance.int < cost.int) { throw 'Insufficient Balance'; }

          purchaseNow(cost, bundleId);

      } catch (error) {
        console.log(error);
          toast.error(error);
          setBlockDiv('');
      }

  }

  const purchaseNow = async (cost, bundleId) => {
      setBlockDiv('purchaseBtn');
      await writeContract.call({ fn: "purchasePack", params: [ bundleId ], successCallback: purchaseCB, value: cost.bns});
      setBlockDiv('');
  }

  const purchaseCB = (hash) => {
      return new Promise(resolve => {
          (async () => {
              const apiToPoll = () => callAPI("checkChainTransaction/main/" + hash);
              const pollContinueCondition = (res) => res.data.state === "pending";
              const pollRes = await pollResults(apiToPoll, pollContinueCondition, 5000);
              resolve();
              if(pollRes.data.state == "success") {
                  //Show success popup
                  Swal.fire({
                      title: "Purchase Complete!",
                      text: "Congratulations on becoming a Vilocity " + auth.userBootstrap.bundles[selectedBundle*1-1].name + " Member",
                      icon: 'success'
                  });
                  await auth.getAppBootstrap();
              } else if(pollRes.data.state == "failed") {
                  toast.error("Failed to purchase.");
              }
          })();
      });
  }

  return (
    <div className='grid grid-cols-1 md:grid-cols-3 gap-5'>
      {auth.userBootstrap?.bundles ? auth.userBootstrap.bundles.map((e, k) => {
        return (
          <div key={k}>
            <div className='bg-gradient-two rounded-xl overflow-hidden'>
              <div className='bg-gradient-five px-3 py-2 text-center font-bold text-3xl text-white text-shadow-one'>{e.name}<p className='text-lg font-normal'>${e.value} Value</p></div>
              <div className='px-4 py-4 text-sm text-white'>{(e.features.split(',')).map((t,i) => {
                return ( 
                  <p className='py-1 flex justify-start items-center gap-2'><CheckBadgeIcon className='text-orange-400 w-6' />{t}</p>
                )})}
              </div>
              <div className='bg-gradient-seven px-3 py-4 text-center font-bold text-xl text-white text-shadow-one'>${e.cost} Membership</div>
              {e.bundlePurchased === null ? 
                <div className='bg-gradient-one border-t-2 border-gray-500 px-4 py-2 text-center flex justify-center cursor-pointer'>
                {writeContract.modalShow ?
                  <div className='text-left max-w-xs'>
                    <PendingModal show={writeContract.modalShow} data={writeContract.modalData} />
                  </div>
                  :
                  <>
                    {purchaseCooldown > new Date() / 1000 ?
                      <>Purchase again in <Countdown date={purchaseCooldown*1000} onComplete={getBalances} /></>
                      :
                      <div>
                        <Button className='block w-full bg-transparent shadow-none text-shadow-one border-none font-bold text-xl sm:text-2xl text-gray-900 uppercase' onClick={() => startPurchase(e.id)} loading={blockDiv === 'purchaseBtn' ? 1 : 0}>
                          Purchase Now
                        </Button>
                      </div>
                    }
                    </>
                }
              </div>
              :
                <div className='bg-green-500 text-white px-4 py-2 text-center flex justify-center font-bold text-xl sm:text-2xl'>
                  ACTIVE
                </div>
              }
            </div>
          </div>
        );
      }) : 
        <LoadingData />
      }
    </div>
  );
};

export default PurchasePack;
