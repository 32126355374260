import { yupResolver } from '@hookform/resolvers/yup';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import * as yup from 'yup';
import { useCallAPI } from '../../../Hooks/callAPI';
import { BlueButton } from '../../../Utility/Buttons';
import { AppFormInput, TitlePrimary } from '../../../Utility/Utility';

const RegisterCourseBox = ({ refresh }) => {

    const callAPI = useCallAPI();
  
    const [formError, setFormError] = useState({});
    const [blockDiv, setBlockDiv] = useState(false);

    const FormSchema = yup.object().shape({
        firstName: yup.string().required().min(1).max(50).label('First Name'),
        lastName: yup.string().required().min(1).max(50).label('Last Name'),
        email: yup.string().required().min(4).max(100).label('Email'),
        password: yup.string().required().min(8).max(50).label('Password')
    });

    const {
        getValues,
        register,
        handleSubmit,
        setValue,
        control,
        formState: { errors, isValid, isDirty }
    } = useForm({ mode: 'onChange', resolver: yupResolver(FormSchema) })

    const registerUser = async () => {
        setBlockDiv(true);
        let formData = getValues();
        const res = await callAPI("signupCoursebox", formData);
        setFormError({});
        if(res.data.state === 1) { //Success
            toast.success('Registration Successful.');
            await refresh();
            setBlockDiv(false);
        } else { //Handle error
            res.data.formError && setFormError(res.data.formError);
            toast.error(res.data.error);
            setBlockDiv(false);
            console.log(res.data);
        }
    }

    return (
        <div className='bg-gradient-three relative rounded-[20px] overflow-hidden px-8 py-6'>
            <div className="">
                <TitlePrimary>Register into IAM ViLocity</TitlePrimary>
                <form onSubmit={handleSubmit(registerUser)}>
                    <AppFormInput name='firstName' label='First Name' register={register} errors={errors} formError={formError} isDirty={isDirty} />
                    <AppFormInput name='lastName' label='Last Name' register={register} errors={errors} formError={formError} isDirty={isDirty} />
                    <AppFormInput name='email' type='email' label='Email' register={register} errors={errors} formError={formError} isDirty={isDirty} />
                    <AppFormInput name='password' type='password' label='Password' register={register} errors={errors} formError={formError} isDirty={isDirty} />
                    <BlueButton type="submit" disabled={!isValid || blockDiv} loading={blockDiv ? 1 :0}>Register now</BlueButton>
                </form>
            </div>
        </div>
    );
};
export default RegisterCourseBox;