import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";
import logo from "../../../Assets/logo.png";
import telegram from "../../../Assets/telegram.png";
import appConfig from "../../../Utility/Config/config";
import { Overlay } from "../../../Utility/Utility";
import Drawer from "./Drawer";
import HeaderDetails from "./HeaderDetails";

const HeaderDash = () => {

  const [drawer, setDrawer] = useState(false);
  const isSmallScreen = useMediaQuery({ maxWidth: 1279 });
  const closeDrawer = () => {
    setDrawer(false);
  };

  return (
    <header className='bg-white px-4 py-3 md:px-6 xl:px-7'>
      <Overlay overlay={drawer && isSmallScreen} />
      <div className='flex items-center justify-between relative z-50'>
        <Link to={'/'}>
          <img
            src={logo}
            alt='logo'
            className='max-w-[100px] lg:max-h-[80px] lg:max-w-[250px]'
          />
        </Link>
        <div className='hidden xl:block ml-auto mr-8 space-y-3'>
          <div className='flex items-center justify-end space-x-5 xl:space-x-8'>
            <HeaderDetails />
          </div>
        </div>
        <div
          className={`${
            drawer && isSmallScreen ? "translate-x-0" : "translate-x-full"
          } fixed h-screen w-[290px] bg-[#222] right-0 top-0 z-50 p-6 transition duration-300 yScroll`}
        >
          <Drawer onCloseDrawer={closeDrawer} />
        </div>

        <button
          onClick={() => setDrawer(true)}
          className='mobile-bar ml-auto mr-4 xl:hidden'
        >
          <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 24 24'
            fill='none'
            stroke='#123377'
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
            className='w-9 h-9 transition duration-300'
          >
            <line x1='3' y1='12' x2='21' y2='12'></line>
            <line x1='3' y1='6' x2='21' y2='6'></line>
            <line x1='3' y1='18' x2='21' y2='18'></line>
          </svg>
        </button>
        <Link to={appConfig.telegram} target="blank" className='w-[48px]'>
          <img className='max-w-full' src={telegram} alt='telegram' />
        </Link>
      </div>
    </header>
  );
};

export default HeaderDash;
