import React, { useEffect, useState } from "react";
import { BarLoader } from "react-spinners";
import { useAccount } from "wagmi";
import currency from "../../../Assets/currency.png";
import user from "../../../Assets/user.png";
import wallet from "../../../Assets/wallet.png";
import { useUserAuth } from "../../../Contexts/authContext";
import { useHelpers } from "../../../Hooks/helpers";
import { useReadContract } from "../../../Hooks/readContract";
import ShortHeaderInfo from "./ShortHeaderInfo";

const HeaderDetails = () => {

  const auth = useUserAuth();
  const account = useAccount();
  const helper = useHelpers();
  const readContract = useReadContract();
  
  const [userBalance, setUserBalance] = useState(false);

  //Get user balance
  useEffect(() => {
    if(auth.appBootstrapped === true && account.isConnected === true) {
        getBalances();
    }
  }, [auth, account.isConnected]);

  const getBalances = async () => {
    setUserBalance(await readContract.getUserBalance());
  }
  
  return (
    <>
      <ShortHeaderInfo icon={currency} title="Wallet Balance" value={userBalance ? (userBalance?.int).toFixed(3) + ' BNB' : <BarLoader className="mt-2" color="#444" />} />
      <ShortHeaderInfo icon={wallet} title="Wallet" value={account?.address && <helper.trimAddress address={account?.address} copy />} />
      <ShortHeaderInfo icon={user} title={'Hi, ' + auth?.userBootstrap?.userData?.username} value={'#' + auth?.userBootstrap?.userData?.id} />     
    </>
  );
};

export default HeaderDetails;
