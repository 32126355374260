const runDevAsProd = false;
const settings = {
    testNet: (process.env.NODE_ENV === 'development' && !runDevAsProd)?true:false, //Change to false for production
    useDevServer: (process.env.NODE_ENV === 'development' && !runDevAsProd)?true:false //Change to false for using production servers
}

const appConfig = {
    version: '1.4.8',
    appLink: 'https://app.vilocityglobal.com/',
    websiteLink: 'https://www.vilocityglobal.com',
    testnet: settings.testNet,
    walletConnectKey: 'f115ad1508ae61bda3fa77df6d72ea26',
    customRPCURL: 'https://ultra-convincing-sanctuary.bsc.quiknode.pro/2981f69ec35c7a6aab268feea68e1c9eb6c80015/', //admin@spicydevs.com
    apiEndpoint: settings.useDevServer?'https://dev.vilocityglobal.com/app/':'https://api.vilocityglobal.com/app/',
    supportedChain: settings.testNet?'97':'56',
    supportedChainName: settings.testNet?'Binance Smart Chain Testnet':'Binance Smart Chain Mainnet',
    scanURL: settings.testNet?'https://testnet.bscscan.com/':'https://bscscan.com/',

    //Custom settings 
    showStats: true,
    showStatsIam: false,

    //URLs
    complan: 'https://www.vilocityglobal.com/complan.pdf',
    support: 'https://vilocity.freshdesk.com/',
    telegram: 'https://t.me/Vilocity',
    youtube: 'https://www.youtube.com/watch?v=2cGLC3fMa9g&t=1s',
    ytChannel: 'https://www.youtube.com/@ViLocityGlobal',
    facebook: 'https://www.facebook.com/groups/vilocityglobal',
    zoom: 'https://us06web.zoom.us/j/83471592609',
    webinar: 'https://www.vilocityinfo.com/'
}

export default appConfig;