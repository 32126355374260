import React from "react";
import { Link } from "react-router-dom";
import bgMembership from "../../../Assets/bg-membership.webp";
import { useUserAuth } from "../../../Contexts/authContext";

const PackItem = ({ pack, showButtons = false, showKey }) => {

  const auth = useUserAuth();

  return (
    <>
      <div
        className={`rounded-lg_next overflow-hidden bg-cover flex flex-1 flex-col justify-between`}
        style={{
          backgroundImage: `url(${bgMembership})`,
          backgroundPosition: "center center",
          backgroundSize: "cover"
        }}
      >
        <div>
          {pack.ranksActive*1 == 0 &&
            <div className="bg-[#a83030] text-center text-white text-sm font-semibold leading-5 px-7 py-4">
              {pack.id*1+5000 - auth?.userBootstrap?.totalPacks*1} more membership count or {3*showKey} total Personals required to immediately start earning
            </div>
          }
          <div className='p-8 space-y-7 md:space-y-7'>
            <div className='flex justify-between'>
              <Blk value={`${pack.id}`} label='ID #' />
              <div className="text-right">
                <Blk value={`${pack.ranksActive}/9`} label='Active Tiers' />
              </div>
            </div>
            <div className='flex justify-around'>
              <BlkTwo value={`$${pack.totalClaimed*2}`} label='Total Claimed' subLabel='To wallet + Holding wallet' />
            </div>
            {showButtons &&
              <div className='text-center !mb-4'>
                <Link to={'/memberships/' + pack.id}><button className='bg-gradient-one hover:bg-gradient-two border-2 border-white text-shadow-one rounded-lg text-md font-trunoSemibold uppercase shadow-xl text-white px-7 py-2'>
                  View Tiers
                </button></Link>
              </div>
            }
          </div>
        </div>
        <div className='bg-gradient-two'>
          <p className='m-0 text-white text-[12px] tracking-[.3px] font-trunoRegular text-center px-4 py-5'>
            <strong className='block font-trunoSemibold text-lg'>
              {auth?.userBootstrap?.totalPacks - pack.id*1} Memberships
            </strong>
            Purchased Globally after this membership
          </p>
        </div>
      </div>
    </>
  );
};
export default PackItem;

const Blk = ({ value, label }) => {
  return (
    <div>
      <strong className='text-white text-shadow-four text-2xl md:text-[24px] font-trunoSemibold leading-tight mb-[2px] block'>
        {value}
      </strong>
      <p className='text-[15px] font-trunoSemibold tracking-[.3px] text-[#123377] '>
        {label}
      </p>
    </div>
  );
};

const BlkTwo = ({ value, label, subLabel = false }) => {
  return (
    <div>
      <strong className='text-white text-3xl md:text-[36px] text-shadow-four font-trunoSemibold text-center leading-9 mb-2 block'>
        {value}
      </strong>
      <p className='text-[15px] text-center font-trunoSemibold tracking-[.3px] text-[#123377]'>
        {label}
      </p>
      <p className="text-[#333] text-[11px] border-[1px] border-[#333] px-2 py-[2px] shadow-md rounded-md mt-1">{subLabel}</p>
    </div>
  );
};