import React from "react";
import st1 from "../../../Assets/st-1.png";
import st2 from "../../../Assets/st-2.png";
import st3 from "../../../Assets/st-3.png";
import i3 from "../../../Assets/i-3.png";
import st4 from "../../../Assets/st-4.png";
import { useUserAuth } from "../../../Contexts/authContext";
import Stats from "./Stats";
const NavStats = () => {
  
  const auth = useUserAuth();

  return (
    <>
      <Stats
        totalCount={auth?.userBootstrap?.siteStats[0].cacheValue}
        text='Users'
        todayCount={auth?.userBootstrap?.siteStats[1].cacheValue}
        icon={i3}
      />
      <Stats
        totalCount={auth?.userBootstrap?.siteStats[2].cacheValue}
        text='Memberships'
        todayCount={auth?.userBootstrap?.siteStats[3].cacheValue}
        icon={st1}
      />
      <Stats
        totalCount={'$' + Math.round(auth?.userBootstrap?.siteStats[6].cacheValue)}
        text='Fast Start'
        todayCount={'$' + Math.round(auth?.userBootstrap?.siteStats[7].cacheValue)}
        icon={st4}
      />
      <Stats
        totalCount={'$' + Math.round(auth?.userBootstrap?.siteStats[4].cacheValue)}
        text='Claimed'
        todayCount={'$' + Math.round(auth?.userBootstrap?.siteStats[5].cacheValue)}
        icon={st2}
      />
      <Stats
        totalCount={'$' + Math.round(auth?.userBootstrap?.siteStats[8].cacheValue)}
        text='Sponsor Match'
        todayCount={'$' + Math.round(auth?.userBootstrap?.siteStats[9].cacheValue)}
        icon={st3}
      />
      {/* <Stats
        totalCount={'$' + Math.round(auth?.userBootstrap?.siteStats[4].cacheValue)}
        text='Membership Auto Purchased'
        todayCount={'$' + Math.round(auth?.userBootstrap?.siteStats[5].cacheValue)}
        icon={st3}
      /> */}
      <Stats
        totalCount={'$' + Math.round(auth?.userBootstrap?.siteStats[4].cacheValue*1 + auth?.userBootstrap?.siteStats[8].cacheValue*1 + auth?.userBootstrap?.siteStats[4].cacheValue*1 + auth?.userBootstrap?.siteStats[6].cacheValue*1)}
        text='Total Global Commissions Paid'
        todayCount={'$' + Math.round(auth?.userBootstrap?.siteStats[5].cacheValue*1 + auth?.userBootstrap?.siteStats[9].cacheValue*1 + auth?.userBootstrap?.siteStats[5].cacheValue*1 + auth?.userBootstrap?.siteStats[7].cacheValue*1)}
        icon={st3}
      />
    </>
  );
};

export default NavStats;
