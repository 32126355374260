import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from 'react';
import { AlertCircle } from 'react-feather';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import * as yup from 'yup';
import { useUserAuth } from '../../Contexts/authContext';
import { useCallAPI } from '../../Hooks/callAPI';
import { BlueButton, OrangeButton } from '../../Utility/Buttons';
import { TitlePrimary } from '../../Utility/Utility';

const FormInput = ({ name, label, type, register, errors, formError, isDirty }) => {
    return (
        <div className='mb-5'>
            <input
                type={type ? type : 'text'} 
                {...register(name)}
                placeholder={label}
                className="bg-transparent border-2 border-[#666] placeholder:text-gray-700 text-dark outline-none rounded-lg_next text-sm font-trunoRegular px-5 py-3 w-full h-[60px]"
            />
            {(isDirty && (errors[name]?.message || formError[name])) &&
                <>
                    {errors[name]?.message && <p className="text-red-900 text-xs mt-3 text-right flex items-center align-middle justify-end"><AlertCircle size={14} className='mr-1' />{errors[name]?.message}</p>}
                    {formError[name] && <p className="text-red-900 text-xs mt-3 text-right flex items-center align-middle justify-end"><AlertCircle size={14} className='mr-1' />{formError[name]}</p>}
                </>
            }
        </div>
    )
}

const AIProduct = () => {

    const callAPI = useCallAPI();
    const authContext = useUserAuth();
  
    const [registered, setRegistered] = useState(false);
    const [formError, setFormError] = useState({});
    const [blockDiv, setBlockDiv] = useState(false);

    //Check for authentication
    useEffect(() => {
        if(authContext.appBootstrapped === true && authContext.loggedIn === true) {
            checkRegistrationState();
        }
    }, [authContext]);

    const checkRegistrationState = async () => {        
        const res = await callAPI("checkAIProductUser");     
        if(res.status && res.data) {
            setRegistered(res.data);
            setValue('email', res.data.email, { shouldDirty: true });
        }
    }

    const FormSchema = yup.object().shape({
        first_name: yup.string().label('First Name'),
        last_name: yup.string().label('Last Name'),
        phone: yup.string().label('Phone'),
        email: yup.string().min(5).max(100).label('Email'),
        password: yup.string().min(8).max(50).label('Password')
    });

    const {
        getValues,
        register,
        handleSubmit,
        setValue,
        formState: { errors, isValid, isDirty }
    } = useForm({ mode: 'onChange', resolver: yupResolver(FormSchema) })

    const registerUser = async () => {
        setBlockDiv(true);
        let formData = getValues();
        const res = await callAPI("signupAIProduct", formData);
        setFormError({});
        if(res.data.state === 1) { //Success
            toast.success('Registration Successful. Please login.');
            checkRegistrationState();
            setBlockDiv(false);
        } else { //Handle error
            res.data.formError && setFormError(res.data.formError);
            toast.error(res.data.error);
            setBlockDiv(false);
            console.log(res.data);
        }
    }

    const loginUser = async () => {
        setBlockDiv(true);
        let formData = getValues();
        const res = await callAPI("loginAIProduct", formData);
        setFormError({});
        if(res.data.state === 1) { //Success
            toast.success('Login Successful. Redirecting..');
            setBlockDiv(false);
            window.open(res.data.loginLink, '_blank').focus();
        } else { //Handle error
            res.data.formError && setFormError(res.data.formError);
            toast.error(res.data.error);
            setBlockDiv(false);
            console.log(res.data);
        }
    }
    
    return (
        <>
            <div className="">
                <TitlePrimary>GPT AI Tool</TitlePrimary>
                <div className='bg-gradient-six max-w-xl p-7 px-10 shadow-xl rounded-2xl text-center mx-auto mt-10'>
                    {!registered ?
                        <form onSubmit={handleSubmit(registerUser)}>
                            <div className='mb-8'>
                                <p className='text-2xl'>Register to get access now</p>
                            </div>
                            <FormInput name='first_name' label='First Name' register={register} errors={errors} formError={formError} isDirty={isDirty} />
                            <FormInput name='last_name' label='Last Name' register={register} errors={errors} formError={formError} isDirty={isDirty} />
                            <FormInput name='email' type='email' label='Email' register={register} errors={errors} formError={formError} isDirty={isDirty} />
                            <FormInput name='password' type='password' label='Password' register={register} errors={errors} formError={formError} isDirty={isDirty} />
                            <FormInput name='phone' label='Phone' register={register} errors={errors} formError={formError} isDirty={isDirty} />
                            <BlueButton type="submit" disabled={!isValid || blockDiv} loading={blockDiv ? 1 :0}>Register now</BlueButton>
                        </form>
                    :
                        <form onSubmit={handleSubmit(loginUser)}>
                            <div className='mb-8'>
                                <p className='text-2xl'>Login to AI Portal</p>
                            </div>
                            <FormInput name='email' label='Email' type='email' register={register} errors={errors} formError={formError} isDirty={isDirty} />
                            <FormInput name='password' type='password' label='Password' register={register} errors={errors} formError={formError} isDirty={isDirty} />
                            <OrangeButton type="submit" disabled={!isValid || blockDiv} loading={blockDiv ? 1 :0}>Login now</OrangeButton>
                        </form>
                    }
                </div>
            </div>
        </>
    );
};

export default AIProduct;