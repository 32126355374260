import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { useAccount } from 'wagmi';
import { AuthWelcome } from '../../Components/auth/Welcome';
import { useUserAuth } from '../../Contexts/authContext';
import { useCallAPI } from '../../Hooks/callAPI';
import { useHelpers } from '../../Hooks/helpers';
import { useReadContract } from '../../Hooks/readContract';
import { useWriteContract } from '../../Hooks/writeContract';
import { OrangeButton } from '../../Utility/Buttons';

const Login = ({ autoLogin = false }) => {
    const callAPI = useCallAPI();
    const history = useNavigate();
    const helpers = useHelpers();
    const authContext = useUserAuth();
    const readContract = useReadContract();
    const writeContract = useWriteContract();
    const account = useAccount();

    const [blockDiv, setBlockDiv] = useState('');
    const [connectAddr, setConnectAddr] = useState(account.address);

    useEffect(() => {
        setConnectAddr(account.address);
    }, [account.address]);

    //Check for authentication
    useEffect(() => {
      if(authContext.appBootstrapped === true && authContext.loggedIn === true) {
          history('/dashboard');
          return;
      }
    }, [authContext]);

    useEffect(() => {
      if(authContext.appBootstrapped === true && authContext.loggedIn === false && autoLogin) {
        loginNow();
        return;
      }
    }, [authContext, account.isConnected]);
    
    const loginNow = async () => {

        setBlockDiv('loginNow');
        try {
            //Check if the address is registered in the users contract
            const userRes = await readContract.read('isUserExists', [connectAddr]);
            if(userRes !== true) { throw 'Register first'; }

            const res = await callAPI("getLoginNonce", { address: connectAddr });
            if(res.data.state !== 1) { throw res.data.error };
            if(res.data.state === 1) { //Success
                //Sign message with nonce to login
                await writeContract.sign(res.data.nonce, postLogin);
                setBlockDiv('');
            }
        } catch (error) {
          console.log(error);
          toast.error(error);
          setBlockDiv('');
        }
        
    }

    const postLogin = async (signatureHash) => {
        //Send the hash signature to verify and login this user
        const loginRes = await callAPI("verifyLogin", { address: connectAddr, signature: signatureHash });
        if(loginRes.data.state !== 1) { toast.error(loginRes.data.error) }
        if(loginRes.data.state === 1) { //Success
            localStorage.setItem('userauthtoken', loginRes.data.auth_token);
            await authContext.getAppBootstrap();
            history('/');
        }
    }

    return (
      <>
        <AuthWelcome />
        <div className='bg-gradient-seven rounded-[20px] border-[3px] border-[#243775] shadow-[20px_20px_50px_0px_rgba(0,0,0,0.40)] px-4 py-6 md:py-12 md:px-14'>
          <div className='space-y-6 md:space-y-6'>
            <div className='text-center'>
              <p className='text-[#243775] font-trunoSemibold text-lg md:text-[19px] mb-3'>
                1. {account.isConnected ? 'Wallet Connected' : 'Connect Wallet'}
              </p>
              <div className='flex justify-center'><w3m-button /></div>
            </div>
            <div className='text-center'>
              <p className='text-[#243775] font-trunoSemibold text-lg md:text-[19px] mb-3'>
                2. Authenticate wallet
              </p>
              <OrangeButton onClick={loginNow} disabled={!account.isConnected || blockDiv === 'loginNow'} loading={blockDiv === 'loginNow' ? 1 : 0}>Login Now</OrangeButton>
            </div>
          </div>
        </div>
      </>
    );
};

export default Login;
