import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import Swal from "sweetalert2";
import { useAccount } from 'wagmi';
import { useUserAuth } from "../../../Contexts/authContext";
import { useCallAPI } from "../../../Hooks/callAPI";
import { useHelpers } from '../../../Hooks/helpers';
import { usePollAPI } from "../../../Hooks/pollAPI";
import { useReadContract } from "../../../Hooks/readContract";
import { useWriteContract } from "../../../Hooks/writeContract";

const RepurchasePacks = () => {

  const auth = useUserAuth();
  const helper = useHelpers();
  const writeContract = useWriteContract();
  const readContract = useReadContract();
  const pollResults = usePollAPI();
  const callAPI = useCallAPI();
  const account = useAccount();

  const [purchaseBalance, setPurchaseBalance] = useState(0);
  const [blockDiv, setBlockDiv] = useState('');
  const [purchaseCooldown, setPurchaseCooldown] = useState(0);

  //Get user balance
  useEffect(() => {
    if(auth.appBootstrapped === true && account.isConnected === true) {
        getBalances();
    }
  }, [auth, account.isConnected]);

  const getBalances = async () => {
    const purchaseBalRes = await readContract.read('getUserPurchaseBalance', [ account.address ]);
    setPurchaseBalance(purchaseBalRes.toString()/100);
    const purchaseRules = await readContract.read('getPurchaseRulesData', [ account.address ] );
    setPurchaseCooldown(purchaseRules[0].toString()*1);
  }

  const startPurchase = async () => {
      
      setBlockDiv('purchaseBtn');
      // DO prelim checks
      try {

          if(account.address.toLowerCase() != auth.userBootstrap.userData.address.toLowerCase()) { throw 'Switch to your registered wallet address'; }
          purchaseNow();

      } catch (error) {
        console.log(error);
          toast.error(error);
          setBlockDiv('');
      }

  }

  const purchaseNow = async () => {
      setBlockDiv('purchaseBtn');
      await writeContract.call({ fn: "purchasePackUsingPurchaseBalance", params: [], successCallback: purchaseCB});
      setBlockDiv('');
  }

  const purchaseCB = (hash) => {
      return new Promise(resolve => {
          (async () => {
              const apiToPoll = () => callAPI("checkChainTransaction/main/" + hash);
              const pollContinueCondition = (res) => res.data.state === "pending";
              const pollRes = await pollResults(apiToPoll, pollContinueCondition, 5000);
              resolve();
              if(pollRes.data.state == "success") {
                  //Show success popup
                  Swal.fire({
                      title: "Purchase Complete!",
                      text: "Purchase completed successfully.",
                      icon: 'success'
                  });
                  await auth.getAppBootstrap();
              } else if(pollRes.data.state == "failed") {
                  toast.error("Failed to purchase.");
              }
          })();
      });
  }

  return (
    <>
      <div className='bg-gradient-seven relative rounded-[20px] overflow-hidden px-8 py-6'>
        <div className='flex justify-between items-center'>
          <div>
            <p className='text-primary text-shadow-four font-trunoSemibold leading-7 text-2xl'>Lifestyle Bucks </p>
            <p className='text-white text-shadow-one font-trunoRegular leading-5 text-md'>Buy travel and lifestyle products</p>
          </div>
          <div className='text-right'>
            <strong className='block text-primary text-shadow-four text-shadow-four leading-tight text-[35px] font-trunoSemibold'>
              <helper.AmountToCurrency amount={purchaseBalance} decimals={2} />
            </strong>
            <span className='text-sm text-shadow-one font-trunoRegular block'>
              Available balance
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default RepurchasePacks;
