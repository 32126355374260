import { Link } from "react-router-dom";
import logo from "../../Assets/logo.png";
import { BlueButton } from "../../Utility/Buttons";

export const AuthWelcome = ({ page = 'login' }) => {
    return (
        <div className='text-center mb-9'>
            <h1 className='text-[#123377] font-trunoSemibold text-[26px] md:text-[40px] mb-1'>
                Welcome to
            </h1>
            <img
                className='w-[200px] md:w-[300px] mx-auto mb-8'
                src={logo}
                alt='Logo'
            />
            {page === 'login' ?
                <>
                    <p className='text-[#090909] text-base mb-2 text-center'>
                        New here?
                    </p>
                    <Link to={'/register'}><BlueButton>
                        Create an account first
                    </BlueButton></Link>
                </>
            :
                <>
                    <p className='text-[#090909] text-base mb-2 text-center'>
                        Already registered?
                    </p>
                    <Link to={'/login'}><BlueButton>
                        Login Now
                    </BlueButton></Link>
                </>
            }
        </div>
    )
}