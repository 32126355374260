import React from "react";
import { useNavigate } from "react-router-dom";
import i1 from "../../../Assets/i-1.png";
import i2 from "../../../Assets/i-2.png";
import i3 from "../../../Assets/i-3.png";
import i4 from "../../../Assets/i-4.png";
import i5 from "../../../Assets/i-5.png";
import i6 from "../../../Assets/i-6.png";
import i8 from "../../../Assets/i-8.png";
import i9 from "../../../Assets/st-4.png";
import graduation from "../../../Assets/graduation.png";
import { useUserAuth } from "../../../Contexts/authContext";
import appConfig from "../../../Utility/Config/config";
import TabSingle from "./TabSingle";
import TabFull from "./TabFull";

const Menu = ({ onCloseDrawer = null }) => {

  const history = useNavigate();
  const auth = useUserAuth();

  const navigate = (page) => {
    history(page);
    onCloseDrawer && onCloseDrawer();
  }
  
  return (
    <>
      <div onClick={() => navigate('/iam')} className="animate-pulse"><TabFull notification='New' icon={graduation} text='IAM ViLocity Dashboard' /></div>
      <div onClick={() => navigate('/')}><TabSingle icon={i1} text='ViLocity Dashboard' /></div>
      <div onClick={() => navigate('/memberships')}><TabSingle icon={i2} text='Memberships' notification={auth?.userBootstrap?.userTotalPacks} /></div>
      <div onClick={() => navigate('/referrals')}><TabSingle icon={i3} text='Referrals' /></div>
      <div onClick={() => navigate('/payments')}><TabSingle icon={i4} text='Payments' /></div>
      <div onClick={() => navigate('/tgAlerts')}><TabSingle icon={i5} text='Alert System' /></div>
      <div onClick={() => navigate('/activities')}><TabSingle icon={i6} text='Activities' /></div>
      <div onClick={() => window.open(appConfig.support, '_blank')}><TabSingle icon={i9} text='Support' /></div>
      <div onClick={() => navigate('/logout')}><TabSingle icon={i8} text='Logout' /> </div>
    </>
  );
};

export default Menu;
