import React from "react";
import { useChainId } from "wagmi";
import appConfig from "../../Utility/Config/config";
const Invalidchain = () => {

    const chain = useChainId();

    return (
        <>
            {chain > 0 && chain !== appConfig.supportedChain*1 &&
                <div className="relative z-10 bg-red-800 text-center px-4 py-4 text-white font-semibold text-sm">
                    Invalid Chain. Connect to Binance Smart Chain.
                </div>
            }
        </>
    );
};

export default Invalidchain;