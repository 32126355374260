import React from "react";
import { useHelpers } from "../../../Hooks/helpers";

const StatsTable = ({ heading = false,  data }) => {
  const helper = useHelpers();
  return (
    <>
      {data &&
        <table className="w-full min-w-max table-auto text-left overflow-hidden bg-gradient-six rounded-lg_next">
          {heading && 
            <thead>
              <tr>
              <th
                className="uppercase font-trunoSemibold bg-gradient-two px-5 py-4 text-white"
                colSpan="2"
              >
                  {heading}
                </th>
              </tr>
            </thead>
          }
          <tbody>
            {Object.entries(data).map((e, k) => {
              return (
                <tr key={k} className="border-b border-[#ED1C24] text-[#123377] tracking-[.45px] font-trunoRegular text-[14px]">
                    <td className=" px-5 md:px-6 py-4 max-w-[140px]">{helper.capitalize(e[0])}</td>
                    <td className="text-right text-md px-5 md:px-6 py-2 font-trunoSemibold text-[#333]">{e[1]}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      }
    </>
  );
};

export default StatsTable;
