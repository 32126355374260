import React from "react";
import Travels from "../../Components/dash/products/Travels";

const TravelProducts = () => {
    
    return (
        <>
            <div>
                <Travels />
            </div>
            
        </>
    );
};

export default TravelProducts;
