import React from "react";
import bgDashboard from "../Assets/bg-dashboard.webp";
import CtaCarousel from "../Components/dash/layout/CtaCarousel";
import HeaderDash from "../Components/dash/layout/HeaderDash";
import Menu from "../Components/dash/layout/Menu";
import MenuMemberBenefits from "../Components/dash/layout/MenuMemberBenefits";
import NavStats from "../Components/dash/layout/NavStats";
import NavStatsIam from "../Components/dash/layout/NavStatsIam";
import { useUserAuth } from "../Contexts/authContext";
import appConfig from "../Utility/Config/config";
import { TitlePrimary } from "../Utility/Utility";
import AppRouter from "./AppRouter";

const Dashboardlayout = () => {

  const auth = useUserAuth();;

  return (
    <div className='bg-[#0B002B] font-trunoRegular'>
      <HeaderDash />
      <main className='overflow-hidden relative px-4 py-6 md:p-6 xl:p-7 min-h-screen'>
        <div
          className='w-full h-full absolute top-0 start-0 bottom-0 end-0 bg-cover bg-center z-0'
          style={{ backgroundImage: `url(${bgDashboard})` }}
        ></div>
        <CtaCarousel />
        <div className='xl:grid xl:grid-cols-[320px_auto] gap-7 relative z-10 mt-7'>
          <div className='space-y-8 hidden xl:block'>
            <div className='grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-5 xl:grid-cols-2 gap-4 md:gap-6'>
              <Menu />
            </div>
            <TitlePrimary>Member Benefits</TitlePrimary>
            <div className='grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-5 xl:grid-cols-2 gap-4 md:gap-6'>
              <MenuMemberBenefits />
            </div>
            {appConfig.showStats &&
              <div>
                <TitlePrimary>Statistics</TitlePrimary>
                <div className='xl:space-y-6 grid grid-cols-1 sm:grid-cols-2 xl:block gap-4 md:gap-6 xl:gap-0'>
                  <NavStats />
                </div>
              </div>
            }
            {appConfig.showStatsIam &&
              <div>
                <TitlePrimary>IAM Statistics</TitlePrimary>
                <div className='xl:space-y-6 grid grid-cols-1 sm:grid-cols-2 xl:block gap-4 md:gap-6 xl:gap-0'>
                  <NavStatsIam />
                </div>
              </div>
            }
          </div>
          <div>
            <AppRouter />
          </div>
        </div>
      </main>
    </div>
  );
};

export default Dashboardlayout;
