import toast from 'react-hot-toast';
import { BaseError, ContractFunctionRevertedError } from 'viem';
import { useAccount, usePublicClient, useWalletClient } from 'wagmi';
import { useUserAuth } from '../Contexts/authContext';
import contractAbiIam from '../Utility/ABI/iam.json';
import contractAbi from '../Utility/ABI/main.json';
import { useCallAPI } from './callAPI';
import { usePendingModal } from "./pendingModal";

export const useWriteContract = () => {

    const client = usePublicClient();
    const account = useAccount();
    const authContext = useUserAuth();
    const wallet = useWalletClient();
    const callAPI = useCallAPI();
    
    const [modalShow, modalData, showModal, hideModal] = usePendingModal();

    const call = async ({ ca = 'main', fn, params, value = 0, address = false, successCallback }) => {
       
        const loadingToast = toast.loading('Confirm transaction from your wallet');
        try {

            let contractAddr;
            let abiToUse;

            switch (ca) {
                case 'main':
                    contractAddr = authContext.systemSettings.CONTRACT_ADDRESS_MAIN;
                    abiToUse = contractAbi;
                    break;

                case 'iam':
                    contractAddr = authContext.systemSettings.CONTRACT_ADDRESS_IAM
                    abiToUse = contractAbiIam;
                    break;
            
                default:
                    break;
            }
            
            const { request } = await client.simulateContract({
                address: contractAddr,
                abi: abiToUse,
                functionName: fn,
                args: params,
                account: address || account.address,
                value: value === 0 ? undefined : value,
            });

            const hash = await wallet.data.writeContract(request);

            showModal(hash);
            await recordTxnInServer(hash, ca);
            toast.dismiss(loadingToast);
            await client.waitForTransactionReceipt( 
                { hash: hash, confirmations: 3 }
            )
            await successCallback(hash);
            hideModal();

        } catch (err) {
            if (err instanceof BaseError) {
                const revertError = err.walk(err => err instanceof ContractFunctionRevertedError);
                if (revertError instanceof ContractFunctionRevertedError) {
                    console.log(revertError.data);                    
                    toast.error(revertError.data?.args[0]);
                } else {
                    toast.error(err.message);
                }
            }
            console.log(err);
            toast.dismiss(loadingToast);
        }

    }

    const sign = async (message, successCallback, address = false) => {
        const loadingToast = toast.loading('Sign message from your wallet app to continue');
        try {
            const signature = await wallet.data.signMessage({ account: address || account.address, message: message });
            toast.dismiss(loadingToast);
            await successCallback(signature);
            return signature;
        } catch (error) {
            toast.dismiss(loadingToast);
            error.shortMessage && toast.error(error.shortMessage);
        }
    }

    const recordTxnInServer = async (hash, ca = 'main') => {
        return new Promise(async resolve => {
            const inputData = {
                hash: hash
            };
            await callAPI('createChainTransaction/' + ca, inputData);
            resolve();
        });
    }

    return {call, sign, modalShow, modalData};
}