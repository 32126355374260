import { useState } from 'react';
import toast from 'react-hot-toast';
import { BottomSheet } from 'react-spring-bottom-sheet';
import { useCallAPI } from '../callAPI';

export const useOpenProductLink = () => {

    const [loading, setLoading] = useState(false);
    const callAPI = useCallAPI();

    const getLink = async (id) => {
        if(!id) {
            toast.error('Product ID is required');
            return;
        }
        setLoading(true);
        const res = await callAPI("validateProduct/" + id);
        if(res.data.state === 1) { //Success
            setTimeout(() => {
                window.open(res.data.link, '_blank');
            });
        } else { //Handle error
            toast.error(res.data.error);
        }
        setLoading(false);

    }

    return {
        getLink,
        loading
    };
}