import { Button } from "@material-tailwind/react";
import React, { useEffect, useState } from "react";
import { useUserAuth } from '../../Contexts/authContext';
import { useCallAPI } from "../../Hooks/callAPI";
import { EmptyRecords, LoadingData, TitlePrimary } from "../../Utility/Utility";

const Academy = () => {

    const auth = useUserAuth();
    const callAPI = useCallAPI();

    const [data, setData] = useState([]);

    //Get user balance
    useEffect(() => {
        if(auth.appBootstrapped === true) {
            getResults();
        }
    }, [auth]);

    const getResults = async () => {
        const res = await callAPI("getAcademyItems");
        setData(res.data);
    }

    return (
        <>
            <div>
                <div className="mb-6">
                    <TitlePrimary>Academy</TitlePrimary>
                </div>
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4">
                    {data && data.length > 0 && data.map((e, i) => {
                        return (
                            <div className="p-3" key={i}>
                                <div className="rounded-xl bg-gradient-five overflow-hidden">
                                    <img src={e.thumbnail} alt={e.title} />
                                    <div className="p-4">
                                        <h4 className="mb-4 leading-5">{e.title}</h4>
                                        <a href={e.link} target="_blank"><Button color="indigo" variant="gradiant">Open</Button></a>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
                {data && data.length === 0 &&
                    <LoadingData />
                }
                {data === false &&
                    <EmptyRecords title='No Academy Items Found' description='All Academy items will be listed here.' />
                }
            </div>
            
        </>
    );
};

export default Academy;
