import { Button } from "@material-tailwind/react";
import React from "react";

const TravelCertificate = ({ e, openProduct }) => {

    return (
        <div className="bg-gradient-one rounded-xl overflow-hidden shadow-lg">
            <img src={e.img} />
            <div className="p-4">
                <h3 className="text-white font-bold text-sm mb-4">{e.desc1}</h3>
                <Button loading={openProduct.loading ? 1 : 0} color="indigo" className="text-white bg-indigo-500 font-bold text-sm" onClick={() => openProduct.getLink(e.id)}>Unlock product</Button>
            </div>
        </div>
    );
};

export default TravelCertificate;
