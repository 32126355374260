import { formatEther } from 'viem';
import { useAccount, usePublicClient } from 'wagmi';
import { useUserAuth } from "../Contexts/authContext";
import contractAbiIam from '../Utility/ABI/iam.json';
import contractAbi from '../Utility/ABI/main.json';

export const useReadContract = () => {

    const authContext = useUserAuth();
    const client = usePublicClient();
    const account = useAccount();

    const contractObj = async (contractAddr, abi, methodName, methodParams) => {
        const data = await client.readContract({
            address: contractAddr,
            abi: abi,
            functionName: methodName,
            args: methodParams
        });
        return data;
    }

    const read = async (method, params) => {
        const res = await contractObj(authContext.systemSettings.CONTRACT_ADDRESS_MAIN, contractAbi, method, params);
        return res;
    }

    const readIam = async (method, params) => {
        const res = await contractObj(authContext.systemSettings.CONTRACT_ADDRESS_IAM, contractAbiIam, method, params);
        return res;
    }

    const getCost = async (amountWei) => {
        const res = await read('getCost', [amountWei.toString()]);
        return { bign: res, int: formatEther(res.toString())*1, bns: res.toString() };
    }

    const getUserBalance = async () => {
        try {
            const res = await client.getBalance({
                address: account.address
            });
            return { bign: res, int: formatEther(res)*1, bign: res.toString() };
        } catch (error) {
            return false;
        }
        
    }

    const getContractBalance = async () => {
        const res = await client.getBalance({
            address: authContext.systemSettings.CONTRACT_ADDRESS_MAIN
        });
        return { bign: res, int: formatEther(res)*1, bign: res.toString() };
    }

    return {read, readIam, getCost, getUserBalance, getContractBalance};
}