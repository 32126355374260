import { Button, Chip } from "@material-tailwind/react";
import React from "react";
import { Compass, Navigation } from "react-feather";
import travelBanner1 from '../../../Assets/travelbanner1.jpg';
import travelBanner2 from '../../../Assets/travelbanner2.jpg';
import travelBanner3 from '../../../Assets/travelbanner3.jpg';
import travelBanner4 from '../../../Assets/travelbanner4.jpg';
import { useOpenProductLink } from "../../../Hooks/products/openProductLink";
import { TitlePrimary } from "../../../Utility/Utility";
import { OrangeButton, PurchaseButton } from "../../../Utility/Buttons";
import TravelCertificate from "./TravelCertificate";
import CommissionsClaim from "../dashboard/CommissionsClaim";
import UserSentCertificates from "./UserSentCertificates";
import UserRedeemedCertificates from "./UserRedeemedCertificates";
import CopyToClipboard from "react-copy-to-clipboard";
import toast from "react-hot-toast";

const Travels = () => {

    const openProduct = useOpenProductLink();

    const travelProducts = [
        {
            id: 2,
            img: travelBanner3,
            desc1: 'Small Activation Fee + Pay Taxes on each package',
        },
        {
            id: 5,
            img: travelBanner3,
            desc1: 'Small Activation Fee + Pay Taxes on each package',
        },
        {
            id: 6,
            img: travelBanner2,
            desc1: 'Small Activation Fee + Pay Taxes on each package',
        },
        {
            id: 7,
            img: travelBanner4,
            desc1: 'Small Activation Fee + Pay Taxes on each package',
        }
    ];

    return (
        <>
            <TitlePrimary>ViLocity Vacations</TitlePrimary>
            <div className="my-5 text-center bg-gradient-seven p-4 px-4 sm:p-6 rounded-xl shadow-xl">
                <div className="font-trunoSemibold text-shadow-one text-3xl">CONGRATULATIONS!</div>
                <div className="text-shadow-one pb-5 mb-5 border-b border-teal-700">You have unlocked travel benefits with your membership purchase.</div>
                <CommissionsClaim />
                <div className="grid grid-cols-1 md:grid-cols-[30%_70%] md:space-x-5 space-y-5 md:space-y-0 mt-5">
                    <div className="text-center space-y-2 bg-gradient-two rounded-xl p-4">
                        <h4 className="mb-4 text-amber-400 font-bold text-2xl">Lite Membership</h4>
                        <div><a href='https://members297.tripvalet.com/membership/signup?invite=1bfc5760-6974-4edb-bee3-f0a476347c1f&referraltype=3' target="blank"><Button size="lg" className="w-full" color="amber">Go to Lite Travel Portal</Button></a></div>
                        <div><a href='https://go.tripvalet.com/membership/login' target="blank"><Button size="sm" className="w-full" color="indigo">Existing Members Log In Here</Button></a></div>
                        <h4 className="text-white font-bold text-md pt-3 pb-3">Travel Certificates available for LITE Membership</h4>
                        <TravelCertificate e={travelProducts[0]} openProduct={openProduct} />
                    </div>
                    <div className="text-center space-y-2 bg-gradient-two rounded-xl p-4">
                        <h4 className="text-amber-400 font-bold text-2xl mb-4">VIP/MAX Membership</h4>
                        <div><Button color="amber" size="lg" className="w-full" onClick={() => openProduct.getLink(3)}>Go to VIP/MAX Travel Portal</Button></div>
                        <div className="py-4 max-w-[400px] mx-auto space-y-2">
                            <p className="text-white text-xl font-bold mb-4">Shopping & Entertainment</p>
                            <CopyToClipboard text="RUZAF1" onCopy={() => toast.success('Copied. Now click "Open Link" to continue')}><Button color="green" size="lg" className="w-full capitalize">Step 1 : Click to copy Referral Code: <b>RUZAF1</b></Button></CopyToClipboard>
                            <Button color="teal" size="lg" className="w-full capitalize" onClick={() => openProduct.getLink(4)}>Step 2 : Open Link</Button>
                        </div>
                        <div className="py-4 max-w-[400px] mx-auto space-y-2">
                            <p className="text-white text-xl font-bold mb-4">TVI Academy</p>
                            <Button color="purple" size="lg" className="w-full" onClick={() => openProduct.getLink(10)}>Open TVI Academy</Button>
                        </div>
                        <h4 className="text-white font-bold text-md pt-3 pb-3">Travel Certificates available for VIP/MAX Membership</h4>
                        <div className="flex flex-wrap gap-5">
                            <div className="flex-1 md:flex-1/2 sm:flex-1">
                                <TravelCertificate e={travelProducts[1]} openProduct={openProduct} />
                            </div>
                            <div className="flex-1 md:flex-1/2 sm:flex-1">
                                <TravelCertificate e={travelProducts[2]} openProduct={openProduct} />
                            </div>
                            <div className="flex-1 md:flex-1/2 sm:flex-1">
                                <TravelCertificate e={travelProducts[3]} openProduct={openProduct} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <UserSentCertificates />
            <UserRedeemedCertificates />
        </>
    );
};

export default Travels;
