import React, { useEffect, useState } from "react";
import { Box, GitMerge, Users, Zap } from "react-feather";
import Slider from "react-slick";
import Timestamp from "react-timestamp";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { useUserAuth } from "../../../Contexts/authContext";
import { useCallAPI } from "../../../Hooks/callAPI";
import { useHelpers } from "../../../Hooks/helpers";

const CarouselCard = ({ data }) => {
  const helper = useHelpers();

  const processIcon = (type) => {
    switch (type) {
      case 'newReferral':
        return (<Users className="text-gray-900 bg-primary p-2 rounded-md" size={35} />)
        break;

      case 'newPack':
        return (<Box className="text-gray-900 bg-primary p-2 rounded-md" size={35} />)
        break;

      case 'rankActivated':
        return (<Zap className="text-gray-900 bg-primary p-2 rounded-md" size={35} />)
        break;
    
      default:
        return (<GitMerge className="text-gray-900 bg-primary p-2 rounded-md" size={35} />)
        break;
    }
  }

  return (
    <div className="bg-gradient-eight rounded-lg shadow-lg mr-4 lg:mr-5 flex justify-between gap-3 items-center px-4 py-3">
      <div>
        <h6 className="text-xs_next font-bold text-white leading-tight">
          {helper.capitalize(data.type)} - ID {data.user_id}
        </h6>
        <p className="text-primary font-xs_prev font-normal"><span><helper.trimAddress address={data.hash} copy /></span> | <small><Timestamp date={data.created} options={{ format:"time", includeDay: true }} relativeTo={Date.now()/1000} /> ago</small></p>
      </div>
      <div>
        {processIcon(data.type)}
      </div>
    </div>
  );
};

const CtaCarousel = () => {

  const callAPI = useCallAPI();
  const auth = useUserAuth();

  const [data, setData] = useState([]);

  //Get user balance
  useEffect(() => {
      if(auth.appBootstrapped === true) {
        getResults();
        let interval = setInterval(() => {
          getResults();
        }, 10000);
        return () => {
          clearInterval(interval);
        };
      }
  }, [auth]);

  const getResults = async (offset) => {
      const res = await callAPI("getCarouselActivities");
      if(res.data !== false && res.data.length > 0) {
        setData([...data, ...res.data]);
      }
  }

  const settings = {
    infinite: true,
    speed: 800,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    nextArrow: "",
    centerMode: true,
    centerPadding: 70,
    responsive: [
      {
        breakpoint: 1800,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          centerPadding: 50,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          centerPadding: 20,
        },
      },
    ],
  };

  return (
    <Slider {...settings}>
      {data.map((item, index) => {
        return (
          <CarouselCard
            key={index}
            data={item}
          />
        );
      })}
    </Slider>
  );
};

export default CtaCarousel;