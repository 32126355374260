import { Button, Chip } from "@material-tailwind/react";
import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useUserAuth } from '../../Contexts/authContext';
import { useCallAPI } from "../../Hooks/callAPI";
import { useHelpers } from "../../Hooks/helpers";
import { EmptyRecords, LoadingData, TitlePrimary } from "../../Utility/Utility";

const Referrals = () => {

    const auth = useUserAuth();
    const helper = useHelpers();
    const callAPI = useCallAPI();

    const [data, setData] = useState([]);
    const [dataLoadFull, setDataLoadFull] = useState(false);
    const [loading, setLoading] = useState(false);

    //Get user balance
    useEffect(() => {
        if(auth.appBootstrapped === true) {
            getResults(0);
        }
    }, [auth]);

    const getResults = async (offset) => {
        setLoading(true);
        const res = await callAPI("getReferrals" + "/" + offset);

        if(offset === 0 && res.data === false) {
            setData(false);
            return;
        }

        if(res.data !== false) {
          setData([...data, ...res.data]);
        } else {
            toast.success("All Rows Loaded");
            setDataLoadFull(true);
        }
        setLoading(false);
    }

    return (
        <>
            <div>
                <div className="mb-6">
                    <TitlePrimary>My Referrals</TitlePrimary>
                </div>
                {data && data.length > 0 &&
                    <>
                        <div className="h-full w-full overflow-scroll">
                            <table className="w-full min-w-max table-auto text-left overflow-hidden bg-gradient-six rounded-lg_next">
                                <thead>
                                    <tr className="">
                                        <th className="uppercase font-trunoSemibold bg-gradient-two px-5 py-4 text-white text-xs">Username &<br/>User ID</th>
                                        <th className="uppercase font-trunoSemibold bg-gradient-two px-5 py-4 text-white text-xs">Registered<br/>Wallet</th>
                                        <th className="uppercase font-trunoSemibold bg-gradient-two px-5 py-4 text-white text-xs">Memberships<br/>Purchased</th>
                                        <th className="uppercase font-trunoSemibold bg-gradient-two px-5 py-4 text-white text-xs">Direct Bonus<br/>Income</th>
                                        <th className="uppercase font-trunoSemibold bg-gradient-two px-5 py-4 text-white text-xs">Checkmatch<br/>Income</th>
                                        <th className="uppercase font-trunoSemibold bg-gradient-two px-5 py-4 text-white text-xs">Registered<br/>Date & Time</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {data && data.length > 0 && data.map((e, i) => {
                                    return (
                                        <tr key={i} className="border-b border-[#ED1C24] text-[#123377] tracking-[.45px] font-trunoRegular text-[14px]">
                                            <td className="px-5 py-4">{e.username} <Chip className="inline ml-2" size="sm" color="gray" value={'#' + e.userId} /></td>
                                            <td className="px-5 py-4"><helper.trimAddress address={e.address} copy /></td>
                                            <td className="px-5 py-4">{e.packs}</td>
                                            <td className="px-5 py-4"><Chip className="inline" color='purple' value={helper.AmountToCurrency({ amount: e.fastStartIncome })} /></td>
                                            <td className="px-5 py-4"><Chip className="inline" color='teal' value={helper.AmountToCurrency({ amount: e.checkMatchIncome})} /></td>
                                            <td className="px-5 py-4">{helper.ProcessDate(e.registered)}</td>
                                        </tr>
                                    )
                                })}
                                </tbody>
                            </table>
                            {!dataLoadFull && <Button color="cyan" disabled={loading} className="mt-4" onClick={() => getResults(data.length)}>Load More</Button>}
                        </div>
                    </>
                }
                {data && data.length === 0 &&
                    <LoadingData />
                }
                {data === false &&
                    <EmptyRecords title='No Referrals Found' description='Your referrals will be listed here.' />
                }
            </div>
            
        </>
    );
};

export default Referrals;
