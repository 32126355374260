import { Button } from "@material-tailwind/react";
import { useEffect, useState } from "react";
import Countdown from "react-countdown";
import toast from "react-hot-toast";
import Timestamp from 'react-timestamp';
import Swal from "sweetalert2";
import { formatGwei } from "viem";
import { useAccount } from "wagmi";
import { useUserAuth } from "../../../Contexts/authContext";
import { useCallAPI } from "../../../Hooks/callAPI";
import { useHelpers } from "../../../Hooks/helpers";
import { PendingModal } from "../../../Hooks/pendingModal";
import { usePollAPI } from "../../../Hooks/pollAPI";
import { useReadContract } from "../../../Hooks/readContract";
import { useWriteContract } from "../../../Hooks/writeContract";
import { PoolHealthDialog, usePoolHealthDialog } from "./hooks/poolHealthDialog";

const PacksActionBlock = () => {

  const helper = useHelpers();
  const auth = useUserAuth();
  const callAPI = useCallAPI();
  const writeContract = useWriteContract();
  const readContract = useReadContract();
  const pollResults = usePollAPI();
  const poolHealth = usePoolHealthDialog();
  const account = useAccount();

  const [blockDiv, setBlockDiv] = useState(false);
  const [unclaimedRewards, setUnclaimedRewards] = useState(0);
  const [accumulatedRewards, setAccumulatedRewards] = useState(0);
  const [activableTiers, setActiivableTiers] = useState(0);
  const [nextClaim, setNextClaim] = useState(0);
  const [lastClaimed, setLastClaimed] = useState(0);
  const [claimCooldown, setClaimCooldown] = useState(0);
  const [claimConfig, setClaimConfig] = useState({});

  //Get user balance
  useEffect(() => {
      if(auth.appBootstrapped === true && account.isConnected === true) {
        getChainData();
      }
  }, [auth, account.isConnected]);

  const getChainData = async () => {

    const res = await readContract.read('getUnclaimedRewardsOfAllPacks', [ account.address, false, true ]);
    setUnclaimedRewards(formatGwei(res.toString()*1));

    const res1 = await readContract.read('getUnclaimedRewardsOfAllPacks', [ account.address, false, false ]);
    setAccumulatedRewards(formatGwei(res1.toString()*1));

    const res2 = await readContract.read('getRankCountToBeActivated', [ account.address ]);
    setActiivableTiers(res2.toString()*1);

    const res3 = await readContract.read('users', [ account.address ]);
    const res4 = await readContract.read('getCooldownByPoolStability');

    setClaimCooldown(res4.toString());
    setLastClaimed(res3[8].toString()*1);
    setNextClaim(res3[8].toString()*1 + res4.toString()*1);

    const cbalance = await readContract.getContractBalance();
    setClaimConfig(helper.getPoolStability(cbalance));

  }

  //Claim all selected packs rewards
  const claimPackRewards = async (proceed = false) => {

      setBlockDiv('claim');

      ///Check minimum withdrawal
      const minClaim = await readContract.read('MINIMUM_CLAIM');

      if(unclaimedRewards < minClaim.toString()*1/100) {
        toast.error('Minimum Claim : $' + minClaim.toString()/100);
        setBlockDiv(false);
        return;
      }

      //Check if the Withdrawals is open from contract
      const withdOpen = await readContract.read('WITHDRAWALS_ACTIVE');
      if(!withdOpen) {
          toast.error('Withdrawals paused currently. Please check back later.');
          setBlockDiv(false);
          return;
      }

      if(proceed === true) {
        await writeContract.call({ fn: "claimMultiplePacks", params: [], successCallback: successCallback });
        setBlockDiv(false);
      } else {
        poolHealth.open();
        setBlockDiv(false);
        return;
      }

  }

  //Actviate ranks
  const activateRanks = async () => {
    setBlockDiv('activate');
    await writeContract.call({ fn: "activatePacks", params: [], successCallback: successCallback });
    setBlockDiv(false);
  }
  
  const successCallback = (hash) => {
      return new Promise(resolve => {
          (async () => {
              const apiToPoll = () => callAPI("checkChainTransaction/main/" + hash);
              const pollContinueCondition = (res) => res.data.state === "pending";
              const pollRes = await pollResults(apiToPoll, pollContinueCondition, 5000);
              resolve();
              if(pollRes.data.state == "success") {
                  //Show success popup
                  Swal.fire({
                      title: "Transaction Completed",
                      text: "Transaction is completed successfully!",
                      icon: 'success',
                      customClass: {
                          confirmButton: 'bg-gradient-two rounded-xl shadow-one text-md text-white font-bold h-[40px] px-10 mb-5'
                      },
                      buttonsStyling: false
                  });
                  await auth.getAppBootstrap();
              } else if(pollRes.data.state == "failed") {
                  toast.error("Failed to process transaction.");
              }
          })();
      });
  }

  return (
    <>
      <div className="border-4 border-blue bg-gradient-one shadow-lg rounded-xl text-left space-y-3">
        <div className="bg-gradient-two px-4 py-3 text-center font-trunoSemibold uppercase text-white text-sm">Membership Action Center</div>
        <div className="px-4 space-y-4 pb-4">
          <div className="w-full flex text-white items-center justify-between space-x-4 border-b-[1px] border-orange-800">
            <div>
              <p className="font-trunoSemibold text-2xl text-shadow-one text-white">{activableTiers}</p>
              <p className="text-xs mb-4 text-blue">Tiers available for activation</p>
            </div>
            <div className="min-w-[128px]">
              <Button onClick={activateRanks} disabled={blockDiv || activableTiers == 0} color="black" size="sm">Activate Tiers</Button>
            </div>
          </div>
          <div className="w-full flex text-white items-center justify-between space-x-4 border-b-[1px] border-orange-900">
            <div>
              <p className="font-trunoSemibold text-2xl text-shadow-one text-white"><helper.AmountToCurrency amount={unclaimedRewards} decimals={4} /></p>
              <p className="text-xs mb-4 text-blue">Rewards available to claim</p>
            </div>
            <div className="min-w-[128px]">
              <Button onClick={claimPackRewards} disabled={blockDiv === 'claim' || unclaimedRewards == 0 || nextClaim > Date.now()/1000} color="blue-gray" size="sm">Global Revenue Share</Button>
            </div>
          </div>
          <div className="text-center w-full">
            <p className="font-trunoSemibold text-2xl text-shadow-one"><helper.AmountToCurrency amount={accumulatedRewards} decimals={4} /></p>
            <p className="text-xs mb-4">Accumulated Rewards</p>
          </div>
          {nextClaim > Date.now()/1000 &&
            <div className="text-center w-full">
              <p className="font-trunoSemibold text-2xl text-shadow-one">
                <Countdown date={nextClaim*1000} renderer={({ days, hours, minutes, seconds }) => { return <span>{days}d : {hours}h : {minutes}m : {seconds}s</span> }} />
              </p>
              <p className="text-xs mb-4">Claim rewards again in</p>
            </div>
          }
          {writeContract.modalShow &&
            <div className='text-left pb-6'>
              <PendingModal show={writeContract.modalShow} data={writeContract.modalData} />
            </div>
          }
          <div className={`rounded-lg shadow-md overflow-hidden ${claimConfig.color}`}>
            <h3 className="text-center p-3 text-white text-md font-bold text-shadow-one">Current Commission Pool Payout</h3>
            <div className="flex justify-between items-center gap-5 px-4 py-2">
              <p className="text-xs text-gray-200">Payout % to your claim wallet</p>
              <p className="font-trunoSemibold text-xl text-shadow-one">
                {claimConfig.claimPercent/100}%
              </p>
            </div>
            <div className="flex justify-between items-center gap-5 px-4 py-2">
              <p className="text-xs text-gray-200">Payout % to your Lifestyle Bucks</p>
              <p className="font-trunoSemibold text-xl text-shadow-one">
                {100-claimConfig.claimPercent/100}%
              </p>
            </div>
            <div className="flex justify-between items-center gap-5 px-4 py-2">
              <p className="text-xs text-gray-200">Commission Waiting Period</p>
              <p className="font-trunoSemibold text-sm text-shadow-one">
                {claimCooldown/86400} days
              </p>
            </div>
            <div className="bg-white text-gray-900 flex justify-between items-center gap-5 px-4 py-2">
              <p className="text-xs text-gray-900">You Last Claimed On</p>
              <p className="font-trunoSemibold text-sm  text-gray-900">
                <Timestamp date={lastClaimed} />
              </p>
            </div>
          </div>
        </div>
      </div>
      <PoolHealthDialog isOpen={poolHealth.isOpen} dismiss={poolHealth.close} rewardsVal={unclaimedRewards} proceedCallback={() => { claimPackRewards(true); poolHealth.close(); }}  />
    </>
  );
};
export default PacksActionBlock;