import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { useAccount } from 'wagmi';
import * as yup from 'yup';
import { AuthWelcome } from '../../Components/auth/Welcome';
import { useUserAuth } from '../../Contexts/authContext';
import { useCallAPI } from '../../Hooks/callAPI';
import { useHelpers } from '../../Hooks/helpers';
import { PendingModal } from '../../Hooks/pendingModal';
import { usePollAPI } from '../../Hooks/pollAPI';
import { useReadContract } from '../../Hooks/readContract';
import { useWriteContract } from '../../Hooks/writeContract';
import { OrangeButton } from '../../Utility/Buttons';
import appConfig from '../../Utility/Config/config';

const Register = ({ takeToHome = false }) => {

    const callAPI = useCallAPI();
    const history = useNavigate();
    const authContext = useUserAuth();
    const params = useParams();
    const writeContract = useWriteContract();
    const readContract = useReadContract();
    const helpers = useHelpers();
    const pollResults = usePollAPI();
    const account = useAccount();
  
    const [formError, setFormError] = useState({});
    const [blockDiv, setBlockDiv] = useState('');
    const [userBalance, setUserBalance] = useState(false);
    const [connectAddr, setConnectAddr] = useState(account.address);

    useEffect(() => {
        setConnectAddr(account.address);
    }, [account.address]);

    useEffect(() => {
        if(params.ref) {
            localStorage.setItem("referrer_username", params.ref);
            if(takeToHome) {
                toast.success("Referred by " + params.ref + ". Taking to Home.");
                takeToHome && window.open(appConfig.websiteLink, '_self');
            } else {
                toast.success("Referred by " + params.ref);
            }
            setValue("referrer_username", params.ref);

        } else {
            takeToHome && window.open(appConfig.websiteLink, '_self');
            if(localStorage.getItem("referrer_username") !== null && localStorage.getItem("referrer_username") !== undefined) {
                toast.success("Referred by " + localStorage.getItem("referrer_username"));
                setValue("referrer_username", localStorage.getItem("referrer_username"));
            }
        }
    }, []);

    //Check for authentication
    useEffect(() => {
        if(authContext.appBootstrapped === true && authContext.loggedIn === true) {
            history('/dashboard');
        }
    }, [authContext]);

    //Get user balance
    useEffect(() => {
        if(account.isConnected === true) {
            (async () => {
                setUserBalance(await readContract.getUserBalance());
            })();
        }
    }, [account.isConnected]);

    const FormSchema = yup.object().shape({
        referrer_username: yup.string().max(20).label('Referrer Username')
    });

    const {
        getValues,
        register,
        handleSubmit,
        setValue,
        formState: { errors, isValid }
    } = useForm({ mode: 'onChange', resolver: yupResolver(FormSchema) })

    const getReferrerAddress = async () => {
        try {
            setBlockDiv('registerNow');
            let formData = getValues();
            formData.address = connectAddr;
            const res = await callAPI("getReferrerAddress", formData);
            setFormError({});
            if(res.data.state === 1) { //Success
                await writeContract.call({ fn: "Register", params: [res.data.referrerAddress], successCallback: registerSuccessCB });
                setBlockDiv('');
            } else { //Handle error
                throw(res.data);
            }
        } catch (error) {
            setBlockDiv('');
            setFormError(error.formError);
            console.log(error.error);
            toast.error(error.error);
        }
    }

    const registerSuccessCB = (hash) => {
        return new Promise(resolve => {
            (async () => {
                const apiToPoll = () => callAPI("checkChainTransaction/main/" + hash);
                const pollContinueCondition = (res) => res.data.state === "pending";
                const pollRes = await pollResults(apiToPoll, pollContinueCondition, 5000);
                resolve();
                if(pollRes.data.state == "success") {
                    //Show success popup
                    Swal.fire({
                        title: "Registration Complete!",
                        text: "Registration completed successfully. You can now login.",
                        icon: 'success',
                        customClass: {
                            confirmButton: 'bg-gradient-two rounded-lg shadow-one text-md text-white h-[40px] px-10 mb-5'
                        },
                        buttonsStyling: false
                    }).then((result) => {
                        if (result.isConfirmed) {
                          history('/login/autoLogin');
                        }
                    });
                    await authContext.getAppBootstrap();
                } else if(pollRes.data.state == "failed") {
                    toast.error("Failed to register.");
                }
             })();
        });
    }
    
    const startRegistration = async () => {
        try {
            if(account.isConnected !== true) { throw 'Failed to connect to wallet'; }
            if(userBalance.int < 0.001) { throw 'Have atleast 0.001 BNB to cover gas cost'; }
            getReferrerAddress();
        } catch (error) {
            toast.error(error);
        }
    }
    
    return (
        <>
            <AuthWelcome page='register' />
            <div className='bg-gradient-seven rounded-[20px] border-[3px] border-[#243775] shadow-[20px_20px_50px_0px_rgba(0,0,0,0.40)] px-4 py-6 md:py-12 md:px-14'>
                <div className='mb-9'>
                    <div className='text-center'>
                        <p className='text-[#243775] font-trunoSemibold text-lg md:text-[19px] mb-3'>
                            1. {account.isConnected ? 'Wallet Connected' : 'Connect Wallet'}
                        </p>
                        <div className='flex justify-center'><w3m-button /></div>    
                    </div>
                </div>
                {writeContract.modalShow ?
                    <PendingModal show={writeContract.modalShow} data={writeContract.modalData} />
                :
                    <form onSubmit={handleSubmit(startRegistration)}>
                        <div className='md:flex justify-center space-y-6 md:space-y-0 md:space-x-14'>
                            <div className='text-center'>
                                <p className='text-[#243775] font-trunoSemibold text-lg md:text-[19px] mb-3'>
                                    2. Enter your Sponsor
                                </p>
                                <input
                                    type="text" 
                                    {...register('referrer_username')}
                                    placeholder="Referrer Username"
                                    className="mb-3 bg-transparent border-2 border-[#243775] placeholder:text-gray-600 text-blue rounded-lg_next text-base md:text-md font-trunoSemibold px-5 py-3 min-w-[290px] h-[60px]"
                                />
                                {formError?.address && <p className="text-red-400 text-sm mb-5 text-right">{formError?.address}</p>}
                                {errors.referrer_username?.message && <p className="text-red-400 text-sm mb-5 text-right">{errors.referrer_username?.message}</p>}
                                {formError?.referrer_username && <p className="text-red-400 text-sm mb-5 text-right">{formError?.referrer_username}</p>}
                                
                            </div>
                            <div className='text-center'>
                                <p className='text-[#243775] font-trunoSemibold text-lg md:text-[19px] mb-3'>
                                    3. Register into Contract
                                </p>
                                <OrangeButton type="submit" disabled={!account.isConnected || !isValid || blockDiv === 'registerNow'} loading={blockDiv === 'registerNow' ? 1 :0}>Register now</OrangeButton>
                            </div>
                        </div>
                    </form>
                }
            </div>
        </>
    );
};
export default Register;