import { ArrowLeftIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { useAccount } from "wagmi";
import PackItem from "../../Components/dash/packs/PackItem";
import RankItem from "../../Components/dash/packs/RankItem";
import { useCallAPI } from "../../Hooks/callAPI";
import { useReadContract } from "../../Hooks/readContract";
import { EmptyRecords, LoadingData, TitlePrimary } from "../../Utility/Utility";

const PackRanks = () => {

    const callAPI = useCallAPI();
    const params = useParams();
    const navigate = useNavigate();
    const readContract = useReadContract();
    const account = useAccount();

    const[pack, setPack] = useState(false);
    const[ranks, setRanks] = useState(false);
    const[packKey, setPackKey] = useState(1);

    useEffect(() => {
        getPackData();
        getPackKey();
    }, []);

    const getPackKey = async () => {
        const res = await readContract.read('viewUserKeyByPackId', [ account.address, params.id ]);
        setPackKey(res.toString()*1);
    }
    
    const getPackData = async () => {
        if(params.id === undefined) {
            toast.error('Invalid Membership ID');
        }

        const res = await callAPI("getPackById/" + params.id);      
        if(res.status && res.data) {
            setPack(res.data);
            if(res.data.id === null) {
                toast.error('Membership not activated yet');
                return;
            }
            getPackRanks(res.data.id);
        } else {
            setPack({});
        }
    }

    const getPackRanks = async (packId) => {
        const res = await callAPI("getRanksByPack/" + packId);      
        if(res.status && res.data) {
            setRanks(res.data);
        } else {
            setRanks([]);
        }
    }

    return (
        <>
            <div>
                <div className="mb-6 flex items-start">
                    <ArrowLeftIcon onClick={() => navigate('/memberships')} className="w-8 text-white mr-2" />
                    <TitlePrimary>Viewing Membership #{pack.id}</TitlePrimary>
                </div>
                <div className='grid grid-cols-1 lg:grid-cols-[1fr_2fr] xl:grid-cols-1 2xl:grid-cols-[1fr_2fr] gap-6 lg:gap-7 items-start'>
                    {pack &&
                        <>
                            {pack.id !== null && 
                                <>
                                    <PackItem pack={pack} showKey={packKey} />
                                    <div className='space-y-8'>
                                        {ranks && ranks.length > 0 && ranks.map((e, i) => {
                                            return (
                                                <RankItem key={i} rank={e} packId={pack.id} packKey={packKey} />
                                            )
                                        })}
                                    </div>
                                </>
                            }
                            {pack.id === null && 
                                <EmptyRecords title='Membership not activated' description='This membership is not activated yet. Try with a different ID.' />
                            }
                        </>
                    }
                    {pack === false &&
                        <LoadingData />
                    }
                </div>
            </div>
        </>
    )
}

export default PackRanks;