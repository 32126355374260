import { CheckBadgeIcon } from "@heroicons/react/24/outline";
import React from "react";
import { DownloadCloud } from "react-feather";
import { useOpenProductLink } from "../../../Hooks/products/openProductLink";

const Ebooks = () => {

    const openProduct = useOpenProductLink();

    const ebooksDetails = [
        'Cooking 4,500+ Ebooks',
        'Hobbies 10,000+ Ebooks',
        'Science 10,000+ Ebooks',
        'Sport 19,000+ Ebooks',
        'Children 4,500+ Ebooks',
        'Crafts 4,500+ Ebooks',
        'Skills 3,500+ Ebooks',
        'Relationships 3,500+ Ebooks',
        'Business 7,000+ Ebooks',
        'Careers 5,500+ Ebooks',
        'Digital 4,500+ Ebooks',
        'Fun 3,500+ Ebooks',
        'Lifestyle 5,000+ Ebooks',
        'Religions 5,000+ Ebooks',
        'Gardening 3,500+ Ebooks',
        'Pets 2,500+ Ebooks',
        'Learning 5,000+ Ebooks',
        'Self-help 5,500+ Ebooks',
        'Music 3,500+ Ebooks',
        '+ More 10,000+ Ebooks'
    ];

    const socialDetails = [
        '1000+ Editable Canva Templates',
        '10,000+ Editable Canva Templates',
        '100,000 Fonts',
        'Facebook Ads Cheat sheet',
        'Digital Brand Building Guidebook',
        'Home Business Guide',
        '600+ Quotes images in PSD',
        '220+ Planned Social Media Posts',
        '500+ Editable Instagram Stories',
        'Social Media Bonuses 1'
    ];
    
    return (
        <div className="">
            <div className="bg-gold-gradient p-4 rounded-10">
                <div className="sm:flex justify-between items-center bg-content px-4 py-3 rounded-10 mb-5">
                    <div className="flex justify-start items-center space-x-4">
                        <DownloadCloud size={40} className="text-white ml-2" />
                        <div className="">
                            <h4 className="text-primary font-bold text-xl">DOWNLOAD YOUR EBOOK BUNDLE</h4>
                            <h5 className="text-white/70 font-bold text-md">300,000+ eBooks & 9 Million+ Articles</h5>
                        </div>
                    </div>
                    <div className="sm:w-[250px] mt-5 sm:mt-0">
                        {/* <MediumDarkButton color="amber" loading={openProduct.loading ? 1 : 0} onClick={() => openProduct.getLink(1)}>Download eBooks <Download size={25} /></MediumDarkButton> */}
                    </div>
                </div>
                <div className="">
                    <div className="">
                        <h4 className="text-white px-8 py-2 rounded-10 inline-block bg-content font-bold text-lg mb-5">What's Included</h4>
                        <div className="sm:grid sm:grid-cols-2 md:grid-cols-3">
                            {ebooksDetails.map((e,k) => {
                                return (
                                    <p className="text-black font-bold text-md flex mb-2 leading-5" key={k}><CheckBadgeIcon className="text-black w-5 mr-1" /> {e}</p>
                                );
                            })}
                        </div>
                    </div>
                    <div className="">
                        <h4 className="text-white px-8 py-2 rounded-10 inline-block bg-content font-bold text-lg my-5">Social Media Bundle</h4>
                        <div className="sm:grid sm:grid-cols-2 md:grid-cols-3">
                            {socialDetails.map((e,k) => {
                                return (
                                    <p className="text-black font-bold text-md flex mb-2 leading-5" key={k}><CheckBadgeIcon className="text-black w-5 mr-1" /> {e}</p>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Ebooks;
