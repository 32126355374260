import { Button, ButtonGroup } from "@material-tailwind/react";
import React, { useEffect, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { RefreshCw, X } from "react-feather";
import { toast } from "react-hot-toast";
import { Link } from "react-router-dom";
import { useUserAuth } from '../../Contexts/authContext';
import { useCallAPI } from "../../Hooks/callAPI";
import appConfig from "../../Utility/Config/config";

const TelegramAlerts = () => {

    const auth = useUserAuth();
    const callAPI = useCallAPI();

    const [tgData, setTgData] = useState(null);

    //Get user balance
    useEffect(() => {
        if(auth.appBootstrapped === true) {
            getResults();
        }
    }, [auth]);

    const getResults = async () => {
        const res = await callAPI("telegramUserData");       
        if(res.status && res.data) {
            setTgData(res.data);
        } else {
            setTgData(false);
        }
    }

    const generateSecretKey = async () => {
        const submitResponse = await callAPI("telegramRegenerateSecretKey");
        if(submitResponse.data.state === 1) { //Success
            getResults();
        } else { //Handle error
            toast.error(submitResponse.data.error);
        }
    }

    const disconnectBot = async () => {
        const submitResponse = await callAPI("telegramDeactivateAccount");
        if(submitResponse.data.state === 1) { //Success
            toast.error('Account disconnected');
            getResults();
        } else { //Handle error
            toast.error(submitResponse.data.error);
        }
    }

    return (
        <>
            <div>
                <div className="bg-gradient-seven sm:flex items-center align-middle justify-between px-4 py-3 rounded-xl mb-6 shadow-xl border-[#000] border-2">
                    <div className="">
                        <div className="font-trunoSemibold text-shadow-one text-xl">JOIN VILOCITY GLOBAL OFFICIAL TELEGRAM GROUP</div>
                        <div className="text-shadow-one">Stay upto date with Official Announcement</div>
                    </div>
                    <div className="mt-4 sm:mt-0">
                        <Link to={appConfig.telegram} target="blank"><Button size="lg">Open Telegram</Button></Link>
                    </div>
                </div>
                <div className="mb-6">
                    <p className="font-trunoSemibold text-shadow-one text-3xl">Setup Telegram Alerts</p>
                    <p className="text-teal-200">Connect your telegram account with ViLocity Global's Telegram Alerts Bot to receive instant notifications about your account activities.</p>
                </div>
                <div className="grid grid-cols-1 sm:grid-cols-3 space-y-5 sm:space-y-0 sm:space-x-5">
                    <div className="bg-gradient-one rounded-lg px-5 py-4 rounded-10">
                        <div className="font-trunoSemibold text-xl text-shadow-one mb-2">Step 1 - Open Telegram Alert Bot</div>
                        <div className="text-blue leading-5 mb-5 text-md">Use the below buttons to open the Telegram Alert Bot. <br/>Go to step 2 once the bot is opened in your telegram app.</div>
                        <div className="flex items-center justify-center space-x-4">
                            <a href={'https://t.me/' + auth?.systemSettings?.TELEGRAM_BOT_ACCOUNT} target="blank"><Button size="lg" color="black">Open Telegram Bot</Button></a>
                            <CopyToClipboard onCopy={() => toast.success('Copied!')} text={auth?.systemSettings?.TELEGRAM_BOT_ACCOUNT}><Button size="lg" color="indigo" >Copy bot username</Button></CopyToClipboard>
                        </div>
                    </div>
                    <div className="bg-gradient-one rounded-lg px-5 py-4 pb-8 rounded-10">
                        <div className="font-trunoSemibold text-xl text-shadow-one mb-2">Step 2 - Generate Secret Key</div>
                        <div className="text-blue leading-5 mb-5 text-md">Get your SECRET_KEY here which you will need to connect your telegram account with your ViLocity account. <br/><br/>Once generated, Go back to the telegram bot and paste the activation string to activate the bot.</div>
                        <div className="flex items-center justify-center space-x-2">
                            {tgData && tgData.secret_key && 
                                <ButtonGroup color="black" size="lg">
                                    <CopyToClipboard onCopy={() => toast.success('Copied!')} text={"/activate " + tgData.secret_key}><Button>/activate {tgData.secret_key}</Button></CopyToClipboard>
                                    <Button onClick={generateSecretKey}><RefreshCw size={15} /></Button>
                                </ButtonGroup>
                            }
                            {(!tgData || !tgData.secret_key) && <Button size="lg" color="indigo" onClick={generateSecretKey} >Generate Secret Key</Button>}
                        </div>
                    </div>
                    <div className="bg-gradient-one rounded-lg px-5 py-4 rounded-10">
                        <div className="font-trunoSemibold text-xl text-shadow-one mb-2">Step 3 - Done</div>
                        <div className="text-blue leading-5 mb-5 text-md">Once you have followed the steps correctly, your bot will be activated and you will start receiving notifications.</div>
                        <div className="flex items-center justify-center space-x-2">
                            {tgData && tgData.client_username && 
                                <ButtonGroup color="indigo" size="lg">
                                    <Button>Connected to {tgData.client_username}</Button>
                                    <Button onClick={disconnectBot}><X size={15} /></Button>
                                </ButtonGroup>
                            }
                            {(!tgData || !tgData.client_username) && <Button size="lg" color="indigo" variant="outline">Not Connected</Button>}
                        </div>
                    </div>
                </div>
            </div>
            
        </>
    );
};

export default TelegramAlerts;
