import { Button, Chip } from "@material-tailwind/react";
import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useUserAuth } from '../../Contexts/authContext';
import { useCallAPI } from "../../Hooks/callAPI";
import { useHelpers } from "../../Hooks/helpers";
import { EmptyRecords, LoadingData, TitlePrimary } from "../../Utility/Utility";

const Payments = () => {

    const auth = useUserAuth();
    const helper = useHelpers();
    const callAPI = useCallAPI();

    const [data, setData] = useState([]);
    const [dataLoadFull, setDataLoadFull] = useState(false);
    const [loading, setLoading] = useState(false);

    //Get user balance
    useEffect(() => {
        if(auth.appBootstrapped === true) {
            getResults(0);
        }
    }, [auth]);

    const getResults = async (offset) => {
        setLoading(true);
        const res = await callAPI("getPayments" + "/" + offset);
        
        if(offset === 0 && res.data === false) {
            setData(false);
            return;
        }

        if(res.data !== false) {
          setData([...data, ...res.data]);
        } else {
            toast.success("All Rows Loaded");
            setDataLoadFull(true);
        }
        setLoading(false);
    }

    return (
        <>
            <div>
                <div className="mb-6">
                    <TitlePrimary>Payments</TitlePrimary>
                </div>
                {data && data.length > 0 &&
                    <>
                        <div className="h-full w-full overflow-scroll">
                            <table className="w-full min-w-max table-auto text-left overflow-hidden bg-gradient-six rounded-lg_next">
                                <thead>
                                    <tr className="uppercase font-trunoSemibold bg-gradient-two text-white text-xs">
                                        <th className="px-5 py-4">From User</th>
                                        <th className="px-5 py-4">From Pack #</th>
                                        <th className="px-5 py-4">From Tier</th>
                                        <th className="px-5 py-4">Amount</th>
                                        <th className="px-5 py-4">Type</th>
                                        <th className="px-5 py-4">Hash</th>
                                        <th className="px-5 py-4">Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {data && data.length > 0 && data.map((e, i) => {
                                    return (
                                        <tr key={i} className="border-b border-[#ED1C24] text-[#123377] tracking-[.45px] font-trunoRegular text-[14px]">
                                            <td className="px-5 py-4">{e.from_id*1 !== 0 ? <>ID {e.from_id}</> : <>System</>}</td>
                                            <td className="px-5 py-4">#{e.pack_id}</td>
                                            <td className="px-5 py-4">{e.rankName}</td>
                                            <td className="px-5 py-4"><helper.AmountToCurrency amount={e.amount} /></td>
                                            <td className="px-5 py-4 max-w-[140px]">{helper.capitalize(e.type)}</td>
                                            <td className="px-5 py-4"><Chip className="inline" color='black' value={helper.trimAddress({ address: e.hash, copy: true })} /></td>
                                            <td className="px-5 py-4">{helper.ProcessDate(e.created)}</td>
                                        </tr>
                                    )
                                })}
                                </tbody>
                            </table>
                            {!dataLoadFull && <Button color="cyan" disabled={loading} className="mt-4" onClick={() => getResults(data.length)}>Load More</Button>}
                        </div>
                    </>
                }
                {data && data.length === 0 &&
                    <LoadingData />
                }
                {data === false &&
                    <EmptyRecords title='No Payments Found' description='Your payments will be listed here.' />
                }
            </div>
            
        </>
    );
};

export default Payments;
