import React, { useEffect, useState } from "react";
import Countdown from "react-countdown";
import { formatGwei } from "viem";
import { useUserAuth } from "../../../Contexts/authContext";
import { useHelpers } from "../../../Hooks/helpers";
import { useReadContract } from "../../../Hooks/readContract";

const RankItem = ({ packId, rank, packKey }) => {

  const auth = useUserAuth();
  const readContract = useReadContract();
  const helper = useHelpers();

  const [unclaimedRewards, setUnclaimedRewards] = useState(0);
  const [isTierActivable, setIsTierActivable] = useState(false);

  useEffect(() => {
    getRankDataFromChain();
    let interval = setInterval(() => {
      getRankDataFromChain();
    }, 60000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const getRankDataFromChain = async () => {
    if(rank.expiration === null) {
      const res1 = await readContract.read('checkIfRankCanActivate', [ packId, rank.id ]);
      setIsTierActivable(res1);
    } else {
      const res = await readContract.read('getUnclaimedRewards', [ packId, rank.id, true ]);
      setUnclaimedRewards(formatGwei(res.toString()*1));
    }
  }
  
  return (
    <>
      <div>
        <div className='flex justify-between px-4 py-2 bg-[#1E2F65] rounded-tl-lg_next rounded-tr-lg_next mx-2 md:mx-5'>
          <h5 className='text-white font-trunoSemibold text-base md:text-lg'>
            <span className='inline-block text-[#F26D1F]'>Tier {rank.id} - </span> {rank.name}
          </h5>
          <p className='text-white font-trunoRegular text-xs'>
            <strong className='inline-block font-trunoSemibold text-base md:text-lg'>
              ${rank.dailyReward} 
            </strong>
            &nbsp;daily for {rank.validityDays} days
          </p>
        </div>

        <div className='bg-white rounded-lg_next shadow-[0px_2px_8px_0px_rgba(0,0,0,0.25)] border-1 border-[rgba(0,0,0,0.40)] grid md:grid-cols-[1.5fr_1fr] items-center gap-6 py-7 px-4 md:px-9'>
          <div className='flex items-center  space-x-8 md:space-x-16'>
            <div className='text-left md:text-center'>
              <strong className='text-[#1D2E63] text-2xl md:text-[18px] font-trunoSemibold leading-tight mb-1 block'>
                <helper.AmountToCurrency amount={rank.totalClaimed*2} decimals={3} />/<helper.AmountToCurrency amount={rank.dailyReward*rank.validityDays} decimals={2} />
              </strong>
              <p className='text-[12px] font-trunoRegular tracking-[.3px] text-[#898585]'>
                Total Claimed
              </p>
              <p className="text-[#777] text-[11px] border-[1px] border-[#777] px-2 py-[2px] shadow-md rounded-md mt-1">To Wallet + Holding Wallet</p>
            </div>
            <div className='text-left md:text-center'>
              <strong className='text-[#F2731E] text-2xl md:text-[18px] font-trunoSemibold leading-tight mb-1 block'>
                <helper.AmountToCurrency amount={unclaimedRewards} />
              </strong>
              <p className='text-[12px] font-trunoRegular tracking-[.3px] text-[#898585]'>
                {rank.isActive === 'yes' ? 'Unclaimed' : 'Locked'} Rewards
              </p>
            </div>
          </div>
          <div>
            <div className='text-right'>
              {rank.expiration === null && !isTierActivable && 
                  <p className="text-xs leading-4 text-red-700">Requires <span className="text-black">{(packId*1+rank.legsRequired*1) - auth?.userBootstrap?.totalPacks*1}</span> more memberships purchased globally {rank.legAndPersonalsRequired === 'yes' ? 'and' : 'or'} <span className="text-black">{packKey*rank.personalsRequired}</span> total personals to activate this tier.</p>
              }
              {rank.expiration === null && isTierActivable && 
                  <p className="text-sm leading-4 text-red-700"><span className="text-black animate-pulse font-trunoSemibold">Tier can be activated.</span> <br/>Go to memberships page to activate.</p>
              }
              {/* Active */}
              {rank.expiration !== null && rank.expiration*1 >= new Date() / 1000 && 
                <div className="">
                  <p className="text-xs text-[#0B002B] leading-3">Earnings expire in</p>
                  <span className="text-green-500 font-bold text-md"><Countdown date={rank.expiration*1000} renderer={({ days, hours, minutes, seconds }) => { return <span>{days}d : {hours}h : {minutes}m : {seconds}s</span> }} /></span>
                </div>
              }
              {/* Expired */}
              {rank.expiration !== null && rank.expiration*1 < new Date() / 1000 && 
                  <p className="text-sm leading-4 text-gray-700">Purchase more memberships to start earning from {rank.name} Tier</p>
              }
            </div>
          </div>
        </div>

        <div className='text-right px-2 md:px-5'>
          {/* Inactive */}
          {rank.expiration === null && 
            <div className='bg-[#292828] font-trunoSemibold rounded-bl-lg_next rounded-br-lg_next text-sm py-2 px-3 text-gray-100 ml-auto inline-block w-[150px] text-center pt-[6px]'>
              Tier Inactive
            </div>
          }
          {/* Active */}
          {rank.expiration !== null && rank.expiration*1 >= new Date() / 1000 && 
            <div className='bg-[#17DF1F] rounded-bl-lg_next rounded-br-lg_next text-shadow-one text-sm py-2 px-3 text-white ml-auto inline-block w-[150px] text-center pt-[6px]'>
              Earnings Active
            </div>
          }
          {/* Expired */}
          {rank.expiration !== null && rank.expiration*1 < new Date() / 1000 && 
            <div className='bg-[#C70E0E] rounded-bl-lg_next rounded-br-lg_next text-shadow-one text-sm py-2 px-3 text-white ml-auto inline-block w-[150px] text-center pt-[6px]'>
              Tier Paid
            </div>
          }
        </div>
      </div>
  </>
  );
};

export default RankItem;
