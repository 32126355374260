import React from "react";
import { useAccount } from "wagmi";
import { useUserAuth } from "../../../Contexts/authContext";
import { useHelpers } from "../../../Hooks/helpers";

const UserDataTable = () => {

  const auth = useUserAuth();
  const helper = useHelpers();
  const account = useAccount();

  return (
    <>
      <table className="w-full min-w-max table-auto text-left overflow-hidden bg-gradient-six rounded-lg_next">
        <tbody>
          <tr className="border-b border-[#ED1C24] text-[#123377] tracking-[.45px] font-trunoRegular text-[14px]">
              <td className=" px-5 md:px-6 py-4  max-w-[140px]">User ID</td>
              <td className="text-right  px-5 md:px-6 py-2 font-trunoSemibold text-[#333]">#{auth?.userBootstrap?.userData?.id}</td>
          </tr>
          <tr className="border-b border-[#ED1C24] text-[#123377] tracking-[.45px] font-trunoRegular text-[14px]">
              <td className=" px-5 md:px-6 py-4  max-w-[140px]">Username</td>
              <td className="text-right  px-5 md:px-6 py-2 font-trunoSemibold text-[#333]">{auth?.userBootstrap?.userData?.username}</td>
          </tr>
          <tr className="border-b border-[#ED1C24] text-[#123377] tracking-[.45px] font-trunoRegular text-[14px]">
              <td className=" px-5 md:px-6 py-4  max-w-[140px]">Registered Wallet</td>
              <td className="text-right  px-5 md:px-6 py-2 font-trunoSemibold text-[#333]"><helper.trimAddress address={auth?.userBootstrap?.userData?.address} copy={true} /></td>
          </tr>
          <tr className="border-b border-[#ED1C24] text-[#123377] tracking-[.45px] font-trunoRegular text-[14px]">
              <td className=" px-5 md:px-6 py-4  max-w-[140px]">Connected Wallet</td>
              <td className="text-right  px-5 md:px-6 py-2 font-trunoSemibold text-[#333]"><helper.trimAddress address={account.address} copy={true} /></td>
          </tr>
          <tr className="border-b border-[#ED1C24] text-[#123377] tracking-[.45px] font-trunoRegular text-[14px]">
              <td className=" px-5 md:px-6 py-4  max-w-[140px]">Referrer ID</td>
              <td className="text-right  px-5 md:px-6 py-2 font-trunoSemibold text-[#333]">#{auth?.userBootstrap?.userReferrerData?.id}</td>
          </tr>
          <tr className="border-b border-[#ED1C24] text-[#123377] tracking-[.45px] font-trunoRegular text-[14px]">
              <td className=" px-5 md:px-6 py-4  max-w-[140px]">Referrer Username</td>
              <td className="text-right  px-5 md:px-6 py-2 font-trunoSemibold text-[#333]">{auth?.userBootstrap?.userReferrerData?.username}</td>
          </tr>
          <tr className="border-b border-[#ED1C24] text-[#123377] tracking-[.45px] font-trunoRegular text-[14px]">
              <td className=" px-5 md:px-6 py-4  max-w-[140px]">Referrer Wallet</td>
              <td className="text-right  px-5 md:px-6 py-2 font-trunoSemibold text-[#333]"><helper.trimAddress address={auth?.userBootstrap?.userReferrerData?.address} copy={true} /></td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default UserDataTable;
