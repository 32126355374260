import React from "react";
import bgLoginPge from "../Assets/bg-login.jpg";
import AppRouter from "./AppRouter";

export default function AuthLayout() {  

  return (
    <div className='min-h-screen relative font-trunoRegular'>
      <div
        className='w-full h-full absolute top-0 start-0 bottom-0 end-0 bg-cover bg-center'
        style={{ backgroundImage: `url(${bgLoginPge})` }}
      ></div>
      <div className='min-h-screen flex flex-col justify-center z-10 relative py-8'>
        <main>
          <div className='max-w-[1080px] mx-auto px-4 lg:px-8'>
              <AppRouter />
          </div>
        </main>
      </div>
    </div>
  );
}