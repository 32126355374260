import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAccount } from "wagmi";
import CopyLink from "../../Components/dash/dashboard/CopyLink";
import PackAvailableForActivation from "../../Components/dash/dashboard/PackAvailableForActivation";
import PurchasePack from "../../Components/dash/dashboard/PurchasePack";
import RepurchasePacks from "../../Components/dash/dashboard/RepurchasePacks";
import SetUsername from "../../Components/dash/dashboard/SetUsername";
import StatsTable from "../../Components/dash/dashboard/StatsTable";
import UserDataTable from "../../Components/dash/dashboard/UserDataTable";
import { useUserAuth } from '../../Contexts/authContext';
import { useCallAPI } from "../../Hooks/callAPI";
import { TitlePrimary } from "../../Utility/Utility";
import RechargePacks from "../../Components/dash/dashboard/RechargePacks";

const Dashboard = () => {

  const auth = useUserAuth();
  const history = useNavigate();
  const account = useAccount();
  const callAPI = useCallAPI();

  const [stats, setStats] = useState(false);

  //Get user balance
  useEffect(() => {
      if(auth.appBootstrapped === true && auth.loggedIn === false) {
        history('/login');
        return;
      }
      if(account.isConnected === true) {
          getResults();
      }
  }, [auth, account.isConnected]);

  const getResults = async () => {
      const res = await callAPI("dashboard");      
      if(res.status && res.data) {
          setStats(res.data);
          //Get contract total value locked 
          // const ttvl = await readContract.getContractBalance();
          // setTtvl(ttvl.int);
          // const rewardsFinal = await readContract.read('getPoolStability', [ 10000 ]);
          // setLpPoolStability(rewardsFinal.toString()/10000*100);
      } else {
          setStats([]);
      }
  }

  return (
    <div className='space-y-7'>
      <PackAvailableForActivation />
      <div>
        <PurchasePack />
        <RechargePacks />
      </div>
      <div className='grid grid-cols-1 md:grid-cols-2 gap-7'>
        <div className='space-y-6 md:space-y-7'>
          <RepurchasePacks />
          <div className="">
            <iframe src="https://calendar.google.com/calendar/embed?height=600&wkst=1&ctz=America%2FNew_York&bgcolor=%23ffffff&src=dmlsb2NpdHlnbG9iYWxAZ21haWwuY29t&src=ZW4udXNhI2hvbGlkYXlAZ3JvdXAudi5jYWxlbmRhci5nb29nbGUuY29t&color=%23039BE5&color=%230B8043" width="100%" height="500" className="rounded-lg bg-white p-2" frameborder="0"></iframe>
          </div>
          <SetUsername />
          <div>
            <TitlePrimary>Your Unique Referral Link</TitlePrimary>

            <div className='bg-gradient-five rounded-lg_next border border-black p-5 md:p-6 space-y-5'>
              <CopyLink
                title='Link to Register page'
                link={auth?.userBootstrap?.userData?.userRefLinkReg}
              />
              <CopyLink
                title='Link to Home page'
                link={auth?.userBootstrap?.userData?.userRefLinkHome}
              />
            </div>
          </div>      
          <div>
            <TitlePrimary>Your Lifestyle and IAM ViLocity Opportunity Referral Links</TitlePrimary>
            <div className='bg-gradient-five rounded-lg_next border border-black p-5 md:p-6 space-y-5'>
              <CopyLink
                title='Link to Lifestyle'
                link={'https://www.vilocityglobal.com/vilocity/?ref=' + auth?.userBootstrap?.userData?.username}
              />
              <CopyLink
                title='Link to IAM ViLocity'
                link={'https://www.vilocityglobal.com/iam/?ref=' + auth?.userBootstrap?.userData?.username}
              />
            </div>
          </div>         
        </div>
        <div>
          <div>
            <TitlePrimary>My Details</TitlePrimary>
            <div>
              <UserDataTable />
            </div>
          </div>
          <div className="my-7">
            <TitlePrimary>Referral Statistics</TitlePrimary>
            <StatsTable data={stats.userReferralsStats} />
          </div>
          <TitlePrimary>Membership Statistics</TitlePrimary>
          <StatsTable data={stats.userStats} />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
