import { NumericStepper } from '@anatoliygatt/numeric-stepper';
import { Button } from "@material-tailwind/react";
import React, { useEffect, useState } from "react";
import Countdown from "react-countdown";
import { toast } from "react-hot-toast";
import Swal from "sweetalert2";
import { useAccount } from 'wagmi';
import packTrans from "../../../Assets/pack-trans.png";
import { useUserAuth } from "../../../Contexts/authContext";
import { useCallAPI } from "../../../Hooks/callAPI";
import { PendingModal } from "../../../Hooks/pendingModal";
import { usePollAPI } from "../../../Hooks/pollAPI";
import { useReadContract } from "../../../Hooks/readContract";
import { useWriteContract } from "../../../Hooks/writeContract";
import { PurchaseButton } from "../../../Utility/Buttons";
import PurchasePack from './PurchasePack';

const RechargePacks = () => {

  const auth = useUserAuth();
  const writeContract = useWriteContract();
  const readContract = useReadContract();
  const pollResults = usePollAPI();
  const callAPI = useCallAPI();
  const account = useAccount();

  const [userBalance, setUserBalance] = useState(false);
  const [blockDiv, setBlockDiv] = useState('');
  const [maxPack, setMaxPacks] = useState(0);
  const [packPurchased, setPackPurchased] = useState(0);
  const [nextReset, setNextReset] = useState(0);

  const [count, setCount] = useState(0);

  //Get user balance
  useEffect(() => {
    if(auth.appBootstrapped === true && account.isConnected === true) {
        getChainData();
    }
  }, [auth, account.isConnected]);

  const getChainData = async () => {
    setUserBalance(await readContract.getUserBalance());
    const maxPacksRes = await readContract.read('getMaxPacksByBundle', [ account.address ] );
    setMaxPacks(maxPacksRes.toString()*1);

    //Decide if the user can buy more packs based on last purchase date
    const purchasedCountRes = await readContract.read('RC_userPurchaseCount', [ account.address ] );
    const lastPurchasedRes = await readContract.read('RC_userLastPurchased', [ account.address ] );
    const monthStartTimestampRes = await readContract.read('getStartOfMonthTimestamp');
    if(lastPurchasedRes.toString()*1 > monthStartTimestampRes.toString()*1) {
        setPackPurchased(purchasedCountRes.toString()*1);
    } else {
        setPackPurchased(0);
    }

    //Get next reset time
    setNextReset(monthStartTimestampRes.toString()*1 + (30 * 24 * 60 * 60));

  }

  const startPurchase = async (bundleId) => {
        
        setBlockDiv('purchaseBtn');
        // DO prelim checks
        try {

            if(account.address.toLowerCase() != auth.userBootstrap.userData.address.toLowerCase()) { throw 'Switch to your registered wallet address'; }

            //Limit maximium packsCount to limit set in contract
            if(count > (maxPack - packPurchased)) {
            throw 'Cannot buy more than limit'; 
            }

            //Get required balance for pro purchase
            const res = await readContract.read('PACK_COST');
        
            const cost = await readContract.getCost(res.toString()*count);
            if(userBalance.int < cost.int) { throw 'Insufficient Balance'; }

            purchaseNow(cost);

        } catch (error) {
        console.log(error);
            toast.error(error);
            setBlockDiv('');
        }

    }

    const purchaseNow = async (cost) => {
        setBlockDiv('purchaseBtn');
        await writeContract.call({ fn: "RechargePacks", params: [ count ], successCallback: purchaseCB, value: cost.bns});
        setBlockDiv('');
    }

    const purchaseCB = (hash) => {
        return new Promise(resolve => {
            (async () => {
                const apiToPoll = () => callAPI("checkChainTransaction/main/" + hash);
                const pollContinueCondition = (res) => res.data.state === "pending";
                const pollRes = await pollResults(apiToPoll, pollContinueCondition, 5000);
                resolve();
                if(pollRes.data.state == "success") {
                    //Show success popup
                    Swal.fire({
                        title: "Recharge Complete!",
                        text: "Congratulations on recharging " + count + " memberships!",
                        icon: 'success'
                    });
                    await auth.getAppBootstrap();
                } else if(pollRes.data.state == "failed") {
                    toast.error("Failed to purchase.");
                }
            })();
        });
    }

return (
    <>
        <div className='bg-gradient-three relative rounded-[20px] box-shadow-[0px_10px_10px_0px_rgba(0,0,0,0.10)] overflow-hidden mt-7 grid grid-cols-1 md:grid-cols-2'>
            <div className='p-5 md:p-6 lg:p-8 pt-4 md:pt-5 lg:pt-7 relative'>
                <h3 className='text-white text-shadow-one font-trunoSemibold tracking-[.9px] mb-4 text-[24px] md:text-3xl'>
                    Recharge Memberships
                </h3>
                <div className='mb-7'>
                    <strong className='block text-white tracking-[1.95px] text-shadow-four leading-tight text-[50px] md:text-[60px] font-trunoSemibold'>
                        $50
                    </strong>
                    <span className='tracking-[.54px] text-base text-white font-trunoRegular block'>
                        Cost Per Membership Recharge
                    </span>
                </div>
                <div className='mb-8'>
                    <NumericStepper
                        minimumValue={1}
                        stepValue={1}
                        maximumValue={maxPack - packPurchased}
                        initialValue={count}
                        size='sm'
                        inactiveTrackColor="#DF7900"
                        activeTrackColor="#162553"
                        activeButtonColor="#ffedd5"
                        inactiveIconColor="#162553"
                        hoverIconColor="#162553"
                        activeIconColor="#9a3412"
                        disabledIconColor="#111"
                        thumbColor="#fff"
                        thumbLabelColor='#162553'
                        thumbShadowAnimationOnTrackHoverEnabled={true}
                        focusRingColor="#111"
                        onChange={setCount}
                    />
                </div>
                {writeContract.modalShow ?
                    <div className='text-left max-w-xs'>
                        <PendingModal show={writeContract.modalShow} data={writeContract.modalData} />
                    </div>
                    :
                    <PurchaseButton onClick={startPurchase} disabled={count === 0} loading={blockDiv === 'purchaseBtn' ? 1 : 0}>
                        Purchase for ${count*50}
                    </PurchaseButton>
                }
            </div>
            <div className='relative'>
                <div
                    className='pack-trans-img min-h-[350px]'
                    style={{
                        backgroundImage: `url(${packTrans})`,
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                    }}
                >
                    <div className='absolute inset-0 flex items-center justify-center'>
                        <div className='bg-gray-900/70 p-3 px-5 rounded-lg text-sm text-white'>
                            Maximum memberships you <br/>can purchase in this time frame
                            <p className='text-4xl font-bold font-ibm-plex-sans text-primary'>{packPurchased}/{maxPack}</p>
                        </div>
                    </div>
                    {nextReset &&
                        <div className='absolute bottom-0 left-0 right-0 p-5 bg-gradient-two text-white text-center'>
                            <p className='text-sm md:text-base'>
                                Next reset in
                            </p>
                            <Countdown date={nextReset * 1000} renderer={({ days, hours, minutes, seconds }) => (
                                <div className='text-2xl md:text-3xl font-trunoSemibold'>
                                    {days}d {hours}h {minutes}m {seconds}s
                                </div>
                            )} />
                        </div>
                    }   
                </div>
            </div>
        </div>
    </>
);
};

export default RechargePacks;