//Import CSS
import { SyncLoader } from "react-spinners";

export default function LayoutLoading() {  
    return (
        <div className="grid h-screen place-items-center bg-[#0B002B]">
            <div className="">
                <SyncLoader
                    color={'#F6BC18'}
                    loading={true}
                    size={20}
                    aria-label="Loading"
                    data-testid="loader"
                />
            </div>
        </div>
    );
}