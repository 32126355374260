import React, { useEffect, useState } from "react";
import { useAccount } from "wagmi";
import PackItem from "../../Components/dash/packs/PackItem";
import PacksActionBlock from "../../Components/dash/packs/PacksActionBlock";
import { useUserAuth } from '../../Contexts/authContext';
import { useCallAPI } from "../../Hooks/callAPI";
import { EmptyRecords, LoadingData, TitlePrimary } from "../../Utility/Utility";
import { Button } from "@material-tailwind/react";
import toast from "react-hot-toast";

const Packs = () => {

    const auth = useUserAuth();
    const callAPI = useCallAPI();
    const account = useAccount();

    const [data, setData] = useState([]);
    const [dataLoadFull, setDataLoadFull] = useState(false);
    const [loading, setLoading] = useState(false);
    
    //Get user balance
    useEffect(() => {
        if(auth.appBootstrapped === true && account.isConnected === true) {
            getResults(0);
        }
    }, [auth, account.isConnected]);

    const getResults = async (offset) => {    
        
        console.log(offset);
        setLoading(true);
        const res = await callAPI("getPacks" + "/" + offset);

        if(offset === 0 && res.data === false) {
            setData(false);
            return;
        }

        if(res.data !== false) {
          setData([...data, ...res.data]);
        } else {
            toast.success("All Memberships Loaded");
            setDataLoadFull(true);
        }
        setLoading(false);
    }

    return (
        <>
            {data && data.length > 0 &&
                <div>
                    <div className="sm:flex justify-between items-center space-y-3 sm:space-y-0">
                        <TitlePrimary>My Memberships</TitlePrimary>
                    </div>
                    <div className='grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-3 gap-6 xl:gap-7'>
                        <PacksActionBlock />
                        {data && data.length > 0 && data.map((e, i) => {
                            return (
                                <PackItem key={i} pack={e} showButtons showKey={i+1} />
                            )
                        })}
                    </div>
                    {!dataLoadFull && <Button color="cyan" disabled={loading} className="mt-4" onClick={() => getResults(data.length)}>Load More</Button>}
                </div>
            }
            {data && data.length === 0 &&
                <LoadingData />
            }
            {data === false &&
                <EmptyRecords title='No Memberships Found' description='Purchased Memberships will be listed here.' />
            }
        </>
    );
};

export default Packs;
