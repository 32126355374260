import React, { useEffect, useState } from "react";
import { useAccount } from "wagmi";
import CourseBox from "../../Components/dash/Iam/CourseBox";
import IamPayments from "../../Components/dash/Iam/Payments";
import PurchaseIam from "../../Components/dash/Iam/PurchaseIam";
import RePurchaseIam from "../../Components/dash/Iam/Repurchase";
import StatsTable from "../../Components/dash/dashboard/StatsTable";
import { useUserAuth } from '../../Contexts/authContext';
import { useCallAPI } from "../../Hooks/callAPI";
import { useParams } from "react-router-dom";
import CoursesEnrolled from "../../Components/dash/Iam/CoursesEnrolled";
import ComingSoon from "../../Components/dash/dashboard/ComingSoon";

const Iam = () => {

  const auth = useUserAuth();
  const callAPI = useCallAPI();
  const account = useAccount();
  const params = useParams();

  const [stats, setStats] = useState(false);
  const [keyInit, setKeyInit] = useState(0);
  
  //Get user balance
  useEffect(() => {
      if(auth.appBootstrapped === true && account.isConnected === true) {
          getResults();
          setKeyInit(keyInit + 1);
      }
  }, [auth, account.isConnected]);
      
  const getResults = async () => {        
      const res = await callAPI("getUserIamStats");     
      if(res.status && res.data) {
        setStats(res.data);
      } else {
        setStats(false);
      }
  }

  return (
    <>
      <ComingSoon />
      <div className='space-y-7' key={keyInit}>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 xl:gap-7">
          <PurchaseIam />
          <CourseBox courseId={params} />
          <RePurchaseIam />
          <StatsTable data={stats} />
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 xl:gap-7">
          <IamPayments />
          <CoursesEnrolled />
        </div>
      </div>
    </>
  );
};

export default Iam;
