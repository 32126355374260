import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from "@material-tailwind/react";
import React, { useEffect, useState } from "react";
import { useForm } from 'react-hook-form';
import { toast } from "react-hot-toast";
import Swal from "sweetalert2";
import { useAccount } from 'wagmi';
import * as Yup from 'yup';
import { useUserAuth } from "../../../Contexts/authContext";
import { useCallAPI } from "../../../Hooks/callAPI";
import { useHelpers } from '../../../Hooks/helpers';
import { usePollAPI } from "../../../Hooks/pollAPI";
import { FormBlock, FormInput } from "../../../Utility/Utility";

const CommissionsClaim = () => {

  const auth = useUserAuth();
  const helper = useHelpers();
  const account = useAccount();
  const pollResults = usePollAPI();
  const callAPI = useCallAPI();

  const [blockDiv, setBlockDiv] = useState(false);
  const [formError, setFormError] = useState({});
  const [balance, setBalance] = useState(0);

  //Get user balance
  useEffect(() => {
    if(auth.appBootstrapped === true && account.isConnected === true) {
        getBalances();
    }
  }, [auth, account.isConnected]);

  const getBalances = async () => {
    const res = await callAPI("getUserUnclaimedTVICommission");
    res.status && res.data.balance && setBalance(res.data.balance);
  }

  const claim = async () => {
    setBlockDiv(true);
    const res = await callAPI("claimCommission");
    console.log(res);
    if(res.data.state === 1) { //Success
        window.open(res.data.payoutLink, '_blank');
        await auth.getAppBootstrap();
        await claimCallback(res.data.order_id);
        setBlockDiv(false);
      } else { //Handle error
        setBlockDiv(false);
        toast.error(res.data.error);
        console.log(res.data);
    }
  }

  const claimCallback = (order_id) => {
      return new Promise(resolve => {
          (async () => {
              const apiToPoll = () => callAPI("checkClaimStatus/" + order_id);
              const pollContinueCondition = (res) => res.data.state === "pending";
              const pollRes = await pollResults(apiToPoll, pollContinueCondition, 5000);
              resolve();
              if(pollRes.data.state == "completed") {
                  //Show success popup
                  Swal.fire({
                      title: "Claim Complete!",
                      text: "Claim completed successfully.",
                      icon: 'success'
                  });
              } else if(pollRes.data.state == "failed") {
                  toast.error("Failed to claim.");
              }
          })();
      });
  }

  const validationSchema = Yup.object().shape({
    email: Yup.string()
        .required('Please enter an email')
        .min(5, "Please enter atleast 5 characters")
        .max(100, "Please keep it short to 100 characters"),
  });

  const {
      register, formState: { errors, isValid, isDirty }, handleSubmit, getValues, setValue, control, watch
  } = useForm({ mode: 'onChange', resolver: yupResolver(validationSchema) })

  const onSubmit = async () => {

      setBlockDiv(true);
      let formData = getValues();
      const res = await callAPI("connectTVIPortal", formData);
      setFormError({});

      if(res.data.state === 1) { //Success
          setBlockDiv(false);
          toast.success("Connected successfully");
          await auth.getAppBootstrap();
        } else { //Handle error
          setBlockDiv(false);
          res.data.formError && setFormError(res.data.formError);
          toast.error(res.data.error);
          console.log(res.data);
      }

  }

  return (
    <>
      <div className='bg-gradient-four relative rounded-[20px] overflow-hidden px-8 pt-6 pb-8'>
        {auth?.userBootstrap?.userData?.tvi_email === null ?
          <>
            <div className='flex justify-center text-center items-center mb-7'>
              <div>
                <p className='text-primary text-shadow-four font-trunoSemibold leading-7 text-2xl'>TripValet Incentives</p>
                <div className='mt-5'><a href='https://members297.tripvalet.com/membership/signup?invite=6e9c6e23-b669-4a7f-a157-18656c8ade02&referraltype=2' target="blank"><Button className="w-full" color="green">Step 1 - Activate LITE Travel Portal</Button></a></div>
              </div>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <FormBlock heading="Step 2 - Enter your LITE Travel Portal Email Below">
                  <div className='mt-3 mb-3'>
                      <FormInput name='email' label="john@google.com" helper="Enter your Lite TripValet registered email" reg={register} formerror={formError} errors={errors} isdirty={isDirty ? 1 : 0} />
                  </div>
                  <Button type="submit" size="lg" color="indigo" disabled={!isValid || !isDirty || blockDiv}>Connect Now</Button>
              </FormBlock>
            </form>
          </>
        :
          <>
            <div className='sm:flex justify-between items-center'>
              <div>
                <p className='text-primary text-shadow-four font-trunoSemibold leading-7 text-2xl'>TripValet Incentives</p>
                <div className='text-gray-900 bg-amber-400 px-3 py-[5px] rounded-lg text-xs text-center mt-2'>
                  Commissions are automatically paid out between 1st - 5th of every month.
                </div>
              </div>
              <div className='sm:text-right text-center mt-4 sm:mt-0'>
                <strong className='block text-primary text-shadow-four text-shadow-four leading-tight text-[35px] font-trunoSemibold'>
                  <helper.AmountToCurrency amount={balance} decimals={2} />
                </strong>
                <span className='text-sm text-shadow-one font-trunoRegular block'>
                  Available Commission
                </span>
              </div>
            </div>
            
            {/* <div className='flex justify-start mt-7 gap-2'>
              <Button color='amber' size='lg' onClick={claim} loading={blockDiv ? 1 : 0} disabled={balance*1 <= 0}>
                Claim COMMISSION
              </Button>
              <Button color='indigo' variant='gradient' size='lg' onClick={claim}>
                Claim History
              </Button>
            </div> */}
          </>
        }
      </div>
    </>
  );
};

export default CommissionsClaim;
