import { Button } from "@material-tailwind/react";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useAccount } from "wagmi";
import { useUserAuth } from "../../../Contexts/authContext";
import { useHelpers } from "../../../Hooks/helpers";
import { useReadContract } from "../../../Hooks/readContract";

const PackAvailableForActivation = () => {

  const helper = useHelpers();
  const auth = useUserAuth();
  const readContract = useReadContract();
  const account = useAccount();

  const [activableTiers, setActiivableTiers] = useState(0);

  //Get user balance
  useEffect(() => {
      if(auth.appBootstrapped === true && account.isConnected === true) {
        getChainData();
      }
  }, [auth, account.isConnected]);

  const getChainData = async () => {
    const res2 = await readContract.read('getRankCountToBeActivated', [ account.address ]);
    setActiivableTiers(res2.toString()*1);
  }

  return (
    <>
      <div className={`bg-blue bg-gradient-seven shadow-lg rounded-xl text-center flex justify-between items-center px-4 py-3 ${activableTiers > 0 ? 'animate-bounce': ''}`}>
        <p className="items-center text-white"><b className="text-2xl">{activableTiers}</b> Tier(s) waiting activation. </p>
        <Link to={'/memberships'}><Button color="amber">Activate Now</Button></Link>
      </div>
    </>
  );
};
export default PackAvailableForActivation;