//Import CSS
import AuthLayout from "./AuthLayout";
import DashboardLayout from "./DashboardLayout";
import { useUserAuth } from '../Contexts/authContext';
import LayoutLoading from "./LayoutLoading";
import appConfig from "../Utility/Config/config";
import Invalidchain from "../Components/others/InvalidChain";
import { Link } from "react-router-dom";

export default function Layout() {  
    const userAuth = useUserAuth();
    return (
        <>  
            <Invalidchain />
            {userAuth.appBootstrapped && userAuth.loggedIn === false && <AuthLayout />}
            {userAuth.appBootstrapped && userAuth.loggedIn === true && <DashboardLayout />}
            {!userAuth?.appBootstrapped && <LayoutLoading />}
            <div className="flex justify-between align-middle items-center p-4">
                <div className="text-center flex items-center align-middle justify-end">
                    <Link target="blank" to={appConfig.facebook}><img className="w-[60px] " src="https://www.vilocityglobal.com/wp-content/uploads/2019/10/fb1.png"/></Link>
                    <Link target="blank" to={appConfig.ytChannel}><img className="w-[60px] " src="https://www.vilocityglobal.com/wp-content/uploads/2019/10/youtube.png"/></Link>
                    <Link target="blank" to={appConfig.telegram}><img className="w-[60px] " src="https://www.vilocityglobal.com/wp-content/uploads/2019/10/telegram.png"/></Link>
                    <Link target="blank" to={appConfig.support}><img className="w-[60px] " src="https://www.vilocityglobal.com/wp-content/uploads/2019/10/gmail-2.png"/></Link>
                    <Link target="blank" to={appConfig.zoom}><img className="w-[60px] " src="https://www.vilocityglobal.com/wp-content/uploads/2019/10/zoom-1.png"/></Link>
                    <Link target="blank" to={appConfig.webinar}><img className="w-[60px] " src="https://www.vilocityglobal.com/wp-content/uploads/2019/10/monitor.png"/></Link>
                </div>
                <div className="text-right sm:text-left font-trunoRegular bg-white text-sm text-gray-800">
                    <div className="">Copyright &copy; ViLocityGlobal.com</div>
                    <div className="text-right text-xs text-gray-600">v{appConfig.version}</div>
                </div>
            </div>
        </>
    );
}