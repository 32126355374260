import { useState } from 'react';
import { HashLoader } from 'react-spinners';
import { useHelpers } from './helpers';

export const usePendingModal = () => {

    const [show, setShow] = useState(false);
    const [data, setData] = useState('');

    const showModal = (data) => {
        setShow(true);
        setData(data);
    }

    const hideModal = () => {
        setShow(false);
    }

    return [show, data, showModal, hideModal];
}

export const PendingModal = ({ data }) => {

    const helpers = useHelpers();

    return (
        <div>
            <div className='flex items-center justify-center'>
                <div className='flex items-center align-middle justify-center mr-4'>
                    <HashLoader
                        color='#222'
                        loading={true}
                        size={40}
                        aria-label="Loading"
                        data-testid="loader"
                        className='mx-auto'
                    />
                </div>
                <p className='text-xs leading-tight font-trunoRegular text-gray-700'>
                    <strong className='block font-trunoSemibold text-blue text-lg leading-4 mb-1'>
                        Validating Transaction
                    </strong>
                    Txn Hash : <b><helpers.trimAddress address={data} copy /></b> | Transaction will be processed in 1-2 mins.
                </p>
            </div>
        </div>
    )
}