import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import countryList from 'react-select-country-list';
import * as yup from 'yup';
import { useUserAuth } from '../../Contexts/authContext';
import { useCallAPI } from '../../Hooks/callAPI';
import { BlueButton, OrangeButton } from '../../Utility/Buttons';
import { AppFormInput, AppFormSelect, TitlePrimary } from '../../Utility/Utility';

const TravelUserPortal = () => {

    const callAPI = useCallAPI();
    const authContext = useUserAuth();
  
    const [registered, setRegistered] = useState(false);
    const [formError, setFormError] = useState({});
    const [blockDiv, setBlockDiv] = useState(false);

    //Check for authentication
    useEffect(() => {
        if(authContext.appBootstrapped === true && authContext.loggedIn === true) {
            checkRegistrationState();
        }
    }, [authContext]);

    const checkRegistrationState = async () => {        
        const res = await callAPI("checkTravelProductUser");     
        if(res.status && res.data) {
            setRegistered(res.data);
            setValue('email', res.data.email, { shouldDirty: true });
        }
    }

    const FormSchema = yup.object().shape({
        first_name: yup.string().required().min(5).max(50).label('First Name'),
        last_name: yup.string().required().min(5).max(50).label('Last Name'),
        address: yup.string().required().min(5).max(200).label('Address'),
        city: yup.string().required().min(1).max(50).label('City'),
        country: yup.string().required().label('Country'),
        phone: yup.string().required().label('Phone'),
        email: yup.string().required().min(5).max(100).label('Email'),
        password: yup.string().required().min(9).max(50).label('Password')
    });

    const {
        getValues,
        register,
        handleSubmit,
        setValue,
        control,
        formState: { errors, isValid, isDirty }
    } = useForm({ mode: 'onChange', resolver: yupResolver(FormSchema) })

    const registerUser = async () => {
        setBlockDiv(true);
        let formData = getValues();
        const res = await callAPI("signupTravelProduct", formData);
        setFormError({});
        if(res.data.state === 1) { //Success
            toast.success('Registration Successful.');
            await checkRegistrationState();
            setBlockDiv(false);
        } else { //Handle error
            res.data.formError && setFormError(res.data.formError);
            toast.error(res.data.error);
            setBlockDiv(false);
            console.log(res.data);
        }
    }

    const loginUser = async () => {
        setBlockDiv(true);
        const res = await callAPI("loginTravelProduct");
        if(res.data.state === 1) { //Success
            toast.success('Redirecting. Please wait..');
            setBlockDiv(false);
            window.open(res.data.loginLink, '_self');
        } else { //Handle error
            toast.error(res.data.error);
            setBlockDiv(false);
            console.log(res.data);
        }
    }

    const countries = useMemo(() => countryList().getData(), []);
    
    return (
        <>
            <div className="">
                <TitlePrimary>Travel Access Portal</TitlePrimary>
                <div className='bg-gradient-six max-w-xl p-7 px-10 shadow-xl rounded-2xl text-center mx-auto mt-10'>
                    {!registered ?
                        <form onSubmit={handleSubmit(registerUser)}>
                            <div className='mb-8'>
                                <p className='text-2xl'>Register to get access now</p>
                            </div>
                            <AppFormInput name='first_name' label='First Name' register={register} errors={errors} formError={formError} isDirty={isDirty} />
                            <AppFormInput name='last_name' label='Last Name' register={register} errors={errors} formError={formError} isDirty={isDirty} />
                            <AppFormInput name='email' type='email' label='Email' register={register} errors={errors} formError={formError} isDirty={isDirty} />
                            <AppFormInput name='password' type='password' label='Password' register={register} errors={errors} formError={formError} isDirty={isDirty} />
                            <AppFormInput name='address' label='Address' register={register} errors={errors} formError={formError} isDirty={isDirty} />
                            <AppFormInput name='city' label='City' register={register} errors={errors} formError={formError} isDirty={isDirty} />
                            <div className="my-4">
                                <AppFormSelect options={[...countries]} name="country" label="Country" helper="Choose your country" control={control} formerror={formError} errors={errors} isdirty={isDirty} />
                            </div>                            
                            <AppFormInput name='phone' label='Phone' register={register} errors={errors} formError={formError} isDirty={isDirty} />
                            <BlueButton type="submit" disabled={!isValid || blockDiv} loading={blockDiv ? 1 :0}>Register now</BlueButton>
                        </form>
                    :
                        <form onSubmit={handleSubmit(loginUser)}>
                            <div className='mb-8'>
                                <p className='text-2xl'>Access Travel Portal Now</p>
                            </div>
                            <OrangeButton onClick={loginUser} disabled={blockDiv} loading={blockDiv ? 1 :0}>Access now</OrangeButton>
                            <p className='mt-4 text-gray-900 font-bold text-sm'>Registered Email : {registered.email}</p>
                        </form>
                    }
                </div>
            </div>
        </>
    );
};
export default TravelUserPortal;