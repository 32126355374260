import { Button } from "@material-tailwind/react";
import { PulseLoader } from "react-spinners";

export const BaseButton = ({ children, ...props }) => {
  return (
    <Button {...props}>
      {!props.loading ?
        <span>{children}</span>
      :
        <div className="flex justify-between align-middle items-center">
          <span>{props.loadingText ? props.loadingText : 'Loading'} </span>
          <PulseLoader
            color={props.loadercolor}
            loading={true}
            size={props.loadersize ? props.loadersize : 20}
            aria-label="Loading"
            data-testid="loader"
          />
        </div>
      }
    </Button>
  );
}

export const PurchaseButton = ({ children, ...props }) => {
    return (
      <BaseButton
        {...props}
        loadercolor="#fff"
        loadersize="10px"
        className={`bg-gradient-four flex items-center justify-between border-2 drop-shadow-[drop-shadow(10px_10px_30px_rgba(0,0,0,0.30)] rounded-lg_next text-lg font-trunoSemibold uppercase text-white min-w-[300px] px-6 py-3  h-[60px] text-shadow-one border-white ${props.loading ? 'opacity-50 pointer-events-none' : ''}`}
      >
        {children}
      </BaseButton>
    );
}

export const BlueButton = ({ children, ...props }) => {

    return (
      <BaseButton
        {...props}
        loadercolor="#fff"
        loadersize="10px"
        className={`bg-gradient-eight border-2 border-[#EE3D24] drop-shadow-[drop-shadow(10px_10px_30px_rgba(0,0,0,0.30)] rounded-lg_next font-trunoSemibold uppercase  px-5 py-3 min-w-[290px] h-[60px] text-shadow-one text-white text-base md:text-lg  ${props.loading ? 'opacity-50 pointer-events-none' : ''}`}
      >
      {children}
    </BaseButton>
  );
}

export const OrangeButton = ({ children, ...props }) => {

  return (
    <BaseButton
      {...props}
      loadercolor="#fff"
      loadersize="10px"
      className={`bg-gradient-one border-2 border-[#243775] drop-shadow-[drop-shadow(10px_10px_30px_rgba(0,0,0,0.30)] rounded-lg_next text-base md:text-lg font-trunoSemibold uppercase px-5 py-3 min-w-[290px] h-[60px] text-shadow-one ${props.loading ? 'opacity-50 pointer-events-none' : ''}`}
    >
    {children}
  </BaseButton>
);
}

export const SmallPrimaryButton = ({ children, ...props }) => {

  return (
    <BaseButton
      {...props}
      loadercolor="#333"
      loadersize="20px"
      className={`bg-limeblue-gradient rounded-10 flex items-center justify-between pl-5 pr-4 h-[45px] text-black transition w-full text-sm font-ibm-plex-sans font-semibold disabled:opacity-50 ${props.loading ? 'opacity-50 pointer-events-none' : ''}`}
    >
    {children}
  </BaseButton>
);
}