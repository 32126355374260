import React from "react";
import { TitlePrimary } from "../../../Utility/Utility";
import HeaderDetails from "./HeaderDetails";
import Menu from "./Menu";
import NavStats from "./NavStats";
import appConfig from "../../../Utility/Config/config";
import MenuMemberBenefits from "./MenuMemberBenefits";
import NavStatsIam from "./NavStatsIam";

const Drawer = ({ onCloseDrawer }) => {
  return (
    <div className='drawer'>
      <button onClick={onCloseDrawer} className='text-white mb-6'>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='30'
          height='30'
          fill='currentColor'
          className='bi bi-x-lg'
          viewBox='0 0 16 16'
        >
          <path d='M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z' />
        </svg>
      </button>

      <div className='space-y-5 mb-4 bg-[#fff] p-3 rounded-lg shadow-xl'>
        <HeaderDetails />
      </div>

      <div className='space-y-7 mt-7'>
        <div className='grid grid-cols-2 gap-4 menu'>
          <Menu onCloseDrawer={onCloseDrawer} />
        </div>
        <TitlePrimary>Member Benefits</TitlePrimary>
        <div className='grid grid-cols-2 gap-4 menu'>
          <MenuMemberBenefits />
        </div>
        {appConfig.showStats &&
          <div>
            <TitlePrimary>Statistics</TitlePrimary>
            <div className='space-y-4'>
              <NavStats />
            </div>
          </div>
        }
        {appConfig.showStatsIam &&
          <div>
            <TitlePrimary>IAM Statistics</TitlePrimary>
            <div className='space-y-4'>
              <NavStatsIam />
            </div>
          </div>
        }
      </div>
    </div>
  );
};

export default Drawer;
