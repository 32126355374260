import React, { useEffect, useState } from "react";
import { useUserAuth } from "../../../Contexts/authContext";
import { useCallAPI } from "../../../Hooks/callAPI";
import { PurchaseButton } from "../../../Utility/Buttons";
import toast from "react-hot-toast";
import { Button } from "@material-tailwind/react";
import { CheckBadgeIcon } from "@heroicons/react/24/solid";

const EnrollCourseBox = ({ courseId }) => {

  const auth = useUserAuth();
  const callAPI = useCallAPI();

  const [courseData, setCourseData] = useState(false);
  const [blockDiv, setBlockDiv] = useState(false);

  //Check for authentication
  useEffect(() => {
    if(auth.appBootstrapped === true && auth.loggedIn === true && courseId.courseId !== undefined) {
        getCourseData();
    }
  }, [auth]);

  const getCourseData = async () => {    
    setBlockDiv(true);
    const res = await callAPI("getCourseData/" + courseId.courseId);     
    if(res.status && res.data.title) {
      setCourseData(res.data);
    } else {
      toast.error(res.data.error);
      console.log(res.data);
    }
    setBlockDiv(false);
  }

  const enroll = async () => {        
    setBlockDiv(true);
    const res = await callAPI("enrollUserIntoCoursebox/" + courseId.courseId);     
    if(res.status && res.data.state === 1) {
      getCourseData();
      await auth.getAppBootstrap();
    } else {
      toast.error(res.data.error);
      console.log(res.data);
    }
    setBlockDiv(false);
  } 
  
  return (
    <>
      <div className='bg-gradient-two relative rounded-[20px] box-shadow-[0px_10px_10px_0px_rgba(0,0,0,0.10)]'>
        <div className='p-7 text-center'>
          <div className='mb-7'>
            <strong className='block text-white tracking-[1.95px] text-shadow-four leading-tight text-[30px] font-trunoSemibold'>
              {auth?.userBootstrap?.userData?.courseCredits_available}
            </strong>
            <span className='tracking-[.54px] text-base text-white font-trunoRegular block'>
              Available Course Credits
            </span>
          </div>
          {courseData ? 
            <div>
              <div className='mb-7'>
                <span className='tracking-[.54px] text-base text-white font-trunoRegular block'>
                  Enroll into course
                </span>
                <strong className='block text-white text-sm font-trunoSemibold'>
                  {courseData.title}
                </strong>
              </div>
              {courseData.enrolled && courseData.enrolled === 'no' ? 
                <div className="flex justify-center">
                  <Button size="lg" color="orange" variant="gradient" onClick={enroll} disabled={blockDiv ? 1 : 0} className="text-black font-trunoSemibold text-lg">
                    Enroll Now
                  </Button>
                </div>
              :
                <div className="text-center">
                  <div className="flex justify-center gap-2 items-center mb-4">
                    <CheckBadgeIcon className="w-[30px] text-green-500" />
                    <p className="text-gray-100 font-bold text-lg">Course Enrolled Successfully</p>
                  </div>
                  <a href={`https://iamvilocity.com/#/courses/${courseData.id}/about`} target="blank">
                    <Button size="lg" color="orange" variant="gradient" onClick={enroll} disabled={blockDiv ? 1 : 0} className="text-black font-trunoSemibold text-lg">Open Course</Button>
                  </a>
                </div>
              }
            </div>
          :
            <div>
              <div className='mb-2'>
                <span className='tracking-[.54px] text-base text-white font-trunoRegular block'>
                  Browse Courses to Enroll
                </span>
              </div>
              <div className="flex justify-center">
                <a href="https://iamvilocity.com" target="blank">
                  <Button size="lg" color="indigo" variant="gradient" loading={blockDiv ? 1 : 0} className="font-trunoSemibold text-lg">
                    Browse Courses
                  </Button>
                </a>
              </div>
              <p className="text-xs mt-5 text-gray-400">Click on a course you want to enroll on the iamvilocity.com website. Once you return you will be prompted to enroll into the course.</p>
            </div>
          }
        </div>
      </div>
    </>
  );
};
export default EnrollCourseBox;