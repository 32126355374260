import React from "react";
import {
  Navigate,
  Route,
  Routes
} from "react-router-dom";

//Import pages
import Login from "../Pages/auth/Login";
import Logout from "../Pages/auth/Logout";
import Register from "../Pages/auth/Register";
import Activities from "../Pages/dash/Activities";
import Dashboard from "../Pages/dash/Dashboard";
import EbooksProduct from "../Pages/dash/EbooksProduct";
import PackRanks from "../Pages/dash/PackRanks";
import Packs from "../Pages/dash/Packs";
import Payments from "../Pages/dash/Payments";
import Referrals from "../Pages/dash/Referrals";
import TelegramAlerts from "../Pages/dash/TelegramAlerts";
import TravelProducts from "../Pages/dash/TravelProducts";
import AIProduct from "../Pages/dash/AIProduct";
import Academy from "../Pages/dash/Academy";
import TravelUserPortal from "../Pages/dash/TVIUserPortal";
import Iam from "../Pages/dash/Iam";

export default function AppRouter() {
  return (
    <Routes>
        {/* Dashboard Routes */}
        <Route exact path="/" element={<Navigate to="/dashboard" />}></Route>

        <Route path="/dashboard" element={<Dashboard />}></Route>
        <Route path="/memberships" element={<Packs />}></Route>
        <Route path="/memberships/:id" element={<PackRanks />}></Route>
        <Route path="/referrals" element={<Referrals />}></Route>
        <Route path="/activities" element={<Activities />}></Route>
        <Route path="/payments" element={<Payments />}></Route>
        <Route path="/tgAlerts" element={<TelegramAlerts />}></Route>
        <Route path="/ebooks" element={<EbooksProduct />}></Route>
        <Route path="/travelProducts" element={<TravelProducts />}></Route>
        <Route path="/aiProducts" element={<AIProduct />}></Route>
        <Route path="/travelUserPortal" element={<TravelUserPortal />}></Route>
        <Route path="/academy" element={<Academy />}></Route>

        {/* Authentication Routes */}
        <Route path="/register" element={<Register />}></Route>
        <Route path="/login" element={<Login />}></Route>
        <Route path="/login/autoLogin" element={<Login autoLogin={true} />}></Route>
        <Route path="/logout" element={<Logout />}></Route>

        {/* //Referral LInk */}
        <Route path="/register/:ref" element={<Register />}></Route>
        <Route path="/home/:ref" element={<Register takeToHome={true} />}></Route>

        <Route path="/iam" element={<Iam />}></Route>
        <Route path="/iam/enroll/:courseId" element={<Iam />}></Route>

    </Routes>
  );
}