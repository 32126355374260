import React from "react";
import { useNavigate } from "react-router-dom";
import botimg from "../../../Assets/solution.png";
import grad from "../../../Assets/graduation.png";
import st2 from "../../../Assets/st-2.png";
import i9 from "../../../Assets/st-4.png";
import { useUserAuth } from "../../../Contexts/authContext";
import TabSingle from "./TabSingle";

const MenuMemberBenefits = ({ onCloseDrawer = null }) => {

  const history = useNavigate();
  const auth = useUserAuth();

  const navigate = (page) => {
    history(page);
    onCloseDrawer && onCloseDrawer();
  }
  
  return (
    <>
      <div onClick={() => navigate('/travelProducts')}><TabSingle icon={st2} text='Vilocity Vacations' /></div>
      <div onClick={() => window.open('https://drive.google.com/drive/folders/1XvmCOD0cEtM52G2UlOpErSumoOtW0gKs?usp=sharing', '_blank')}><TabSingle icon={i9} text='Marketing Resources' /></div>
      {/* <div onClick={() => navigate('/aiProducts')}><TabSingle icon={botimg} text={<>GPT AI <br/> Tools</>} /></div> */}
      <div onClick={() => window.open('https://www.vilocityrecognition.com/', '_blank')}><TabSingle icon={i9} text='Recognition Banners' /></div>
      <div onClick={() => window.open('https://www.youtube.com/playlist?list=PLuts5TJWLBFus1evynsPspw5aT6YRiJaQ', '_blank')}><TabSingle icon={i9} text="Leadership Broadcast" /></div>
      <div onClick={() => window.open('https://youtube.com/playlist?list=PLuts5TJWLBFutXmO-G5bGlNIfE4JTYOTt&si=HNJb89-mDASgbTpL', '_blank')}><TabSingle icon={i9} text='Global Chairman Guide Start Here' /></div>
      <div onClick={() => window.open('https://youtube.com/playlist?list=PLuts5TJWLBFudmNd-D6Tt1ReYaP2uIkmj&si=-ClS4q_9WTqYkYZg', '_blank')}><TabSingle icon={grad} text='IAM ViLocity FAQs' /></div>


      
    </>
  );
};

export default MenuMemberBenefits;
