import { IconButton } from "@material-tailwind/react";
import React, { useEffect, useState } from "react";
import { useUserAuth } from '../../../Contexts/authContext';
import { useCallAPI } from "../../../Hooks/callAPI";
import { EmptyRecords, LoadingData, TitlePrimary } from "../../../Utility/Utility";

const UserRedeemedCertificates = () => {

    const auth = useUserAuth();
    const callAPI = useCallAPI();

    const [data, setData] = useState(false);
    const [totalPages, setTotalPages] = useState(10);
    const [currPage, setCurrPage] = useState(2);

    //Get user balance
    useEffect(() => {
        if(auth.appBootstrapped === true) {
            getResults(1);
        }
    }, [auth]);

    const getResults = async (page) => {
        const res = await callAPI("getUserRedeemedCertificates" + "/" + page);
        if(res.data.result) {
            setData(res.data.result);
            setTotalPages(res.data.totalPages);
            setCurrPage(res.data.currentPage);
        } else {
            setData([]);
        }
    }
    
    return (
        <>
            <div className="mt-5">
                <div className="mb-6">
                    <TitlePrimary>Redeemed Certificates</TitlePrimary>
                </div>
                {data && data.length > 0 &&
                    <>
                        <div className="h-full w-full overflow-scroll">
                            <table className="w-full min-w-max table-auto text-left overflow-hidden bg-gradient-six rounded-lg_next">
                                <thead>
                                    <tr className="uppercase font-trunoSemibold bg-gradient-two text-white text-xs">
                                        <th className="py-4 px-5">Certificate ID</th>
                                        <th className="py-4 px-5">Receiver Email<br/>Receiver Name</th>
                                        <th className="py-4 px-5">Certificate Name<br/>Certificate Code</th>
                                        <th className="py-4 px-5">Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {data && data.length > 0 && data.map((e, i) => {
                                    return (
                                        <tr key={i} className={`border-b border-[#ED1C24] text-[#123377] tracking-[.45px] font-trunoRegular text-[14px] ${e.readOn === 'yes' && 'opacity-60'}`}>
                                            <td className="px-5 py-4">{e.certificateIdentity}</td>
                                            <td className="px-5 py-4">{e.receiver_email}<br/>{e.receiver_name}</td>
                                            <td className="px-5 py-4">{e.certificateTitle}<br/>{e.certificateCode}</td>
                                            <td className="px-5 py-4">{e.createdAt}</td>
                                        </tr>
                                    )
                                })}
                                </tbody>
                            </table>
                        </div>
                        <div className="p-4">
                            <div className="flex flex-wrap justify-center items-center gap-2">
                                {Array.from({ length: totalPages }, (_, index) => index + 1).map(page => (
                                    <IconButton key={page} onClick={() => getResults(page)} variant={currPage === page ? 'gradient':'outlined'} color='orange' size="sm">{page}</IconButton>
                                ))}
                            </div>
                        </div>
                    </>
                }
                {data && data.length === 0 &&
                    <EmptyRecords title='No Activity Found' description='Your certificate activities will be listed here.' />
                }
                {data === false &&
                    <LoadingData />
                }
            </div>
        </>
    );
};

export default UserRedeemedCertificates;
