import React from "react";
import st1 from "../../../Assets/st-1.png";
import st2 from "../../../Assets/st-2.png";
import st4 from "../../../Assets/st-4.png";
import { useUserAuth } from "../../../Contexts/authContext";
import Stats from "./Stats";
const NavStatsIam = () => {
  
  const auth = useUserAuth();

  return (
    <>
      <Stats
        totalCount={auth?.userBootstrap?.iamSiteStats[0].cacheValue}
        text='Course Credits Purchased'
        todayCount={auth?.userBootstrap?.iamSiteStats[1].cacheValue}
        icon={st1}
      />
      <Stats
        totalCount={'$' + Math.round(auth?.userBootstrap?.iamSiteStats[2].cacheValue)}
        text='Direct Bonus Earned'
        todayCount={'$' + Math.round(auth?.userBootstrap?.iamSiteStats[3].cacheValue)}
        icon={st4}
      />
      <Stats
        totalCount={'$' + Math.round(auth?.userBootstrap?.iamSiteStats[4].cacheValue)}
        text='Checkmatch Earned'
        todayCount={'$' + Math.round(auth?.userBootstrap?.iamSiteStats[5].cacheValue)}
        icon={st2}
      />
    </>
  );
};

export default NavStatsIam;
