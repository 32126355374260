import React, { useEffect, useState } from "react";
import { useUserAuth } from "../../../Contexts/authContext";
import { useCallAPI } from "../../../Hooks/callAPI";
import { LoadingData } from "../../../Utility/Utility";
import EnrollCourseBox from "./EnrollCourseBox";
import RegisterCourseBox from "./RegisterCourseBox";

const CourseBox = ({ courseId }) => {

  const auth = useUserAuth();
  const callAPI = useCallAPI();

  const [userDetails, setUserDetails] = useState(null);

  //Check for authentication
  useEffect(() => {
    if(auth.appBootstrapped === true && auth.loggedIn === true) {
        checkRegistrationState();
    }
  }, [auth]);

  const checkRegistrationState = async () => {        
      const res = await callAPI("getCourseBoxUser");     
      if(res.status) {
          setUserDetails(res.data)
      }
  }

  return (
    <>
      {userDetails === null ? 
        <LoadingData />
      :
        <>
        {userDetails === false ?
          <RegisterCourseBox refresh={checkRegistrationState} />
        :
          <div>
            <EnrollCourseBox courseId={courseId} />
            <div className='bg-gradient-five relative rounded-[10px] text-center mt-4 overflow-hidden px-3 py-4 text-xs'>
              IamVilocity.com Registered Email <p className="text-lg font-bold text-blue">{userDetails.email}</p>
            </div>
          </div>
        }
        </>
    }
    </>
  );
};
export default CourseBox;